<public-header (NavigatePage)="navigatePageTo($event)"></public-header>
<div class="wrapper-page" style="max-width: 900px;margin-top: 35px;margin-bottom: 0;">
	<div class="card overflow-hidden account-card mx-3" style="margin-bottom:0px;">
		<div class="bg-primary p-4 text-white text-center position-relative">
			<h4 class="font-20 m-b-5">Sign Up to Hagglr</h4>
			<p class="text-white-50 mb-4">Get your Hagglr account now.</p>
			<a class="logo logo-admin"><img src="assets/img/fav-logo.png" height="38" alt="logo" (click)="GoHome()"></a>
		</div>
		<div class="account-card-content">
			<ngb-alert *ngIf="alert.type !=''" [type]="alert.type" (close)="alert.type = ''">{{ alert.message }}
			</ngb-alert>
			<form class="form-horizontal m-t-30" (ngSubmit)="loginSubmit()" [formGroup]="loginForm"
				action="dashboard.html" method="post">

				<div class="row">
					<div class="col-md-6 vright-pad">
						<div class="col-md-12">
							<div class="form-group">
								<input type="text" formControlName="email" class="form-control" id="email"
									formControlName="email" autocomplete="off"
									[ngClass]="{'is-invalid': submitted && f.email.errors}" placeholder="Email">
								<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
									<div *ngIf="f.email.errors.required">Please provide a
										business email.</div>
									<div *ngIf="f.email.errors.email">Email must be a valid business email</div>
								</div>
							</div>
						</div>
						<div class="col-md-12">
							<div class="form-group">
								<input type="password" formControlName="password" id="password" class="form-control"
									[ngClass]="{'is-invalid': submitted && f.password.errors}" placeholder="Password">
								<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
									<div *ngIf="f.password.errors.required">Please provide a password.
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-12 vbtn7">
							<button type="submit" class="btn btn-outline btn-info floater">Login</button>
                           <a routerLink="/public/forgotpassEmail"> <p class="floater forgot-btn"><i class="mdi mdi-lock"></i> Forgot your password?</p></a>
						</div>					

					</div>
					<div class="col-md-6 vsocial-btn">
						<div class="social-auth-links vlogin-pad text-center">
							<div class="row">
								<div class="col-md-12">
									<a routerLink="/public/login"
										class="btn btn-block btn-social btn-facebook btn-flat"  (click)="loginFb()"><i
											class="mdi mdi-facebook"></i> Login using Facebook</a>
								</div>
								<div class="col-md-12">
									<a routerLink="/public/login"
										class="btn btn-block btn-social btn-google btn-flat"  #loginRef><i
											class="mdi mdi-google-plus"></i> Login using Google</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
	<div class="m-t-10 text-center">
		<p>Don't have an account yet? <a routerLink="/public/registration" class="font-500 text-primary">Sign Up</a></p>
		<!--<p>&copy; 2018-2021 Hagglr, Inc.</p>-->
	</div>
</div>
<public-footer (NavigatePage)="navigatePageTo($event)"></public-footer>