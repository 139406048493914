import { Injectable } from '@angular/core';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  { name: 'sc_1', src: 'assets/js/vendor/jquery.js' },
  { name: 'sc_2', src: 'assets/js/vendor/bootstrap.min.js' },
  { name: 'sc_3', src: 'assets/js/waypoints.min.js' },
  { name: 'sc_4', src: 'assets/js/wow.js' },
  { name: 'sc_5', src: 'assets/js/counterup.js' },
  { name: 'sc_6', src: 'assets/js/imagesloaded.js' },
  { name: 'sc_7', src: 'assets/js/isotope.js' },
  { name: 'sc_8', src: 'assets/js/tilt.js' },
  { name: 'sc_9', src: 'assets/js/anime.js' },
  { name: 'sc_10', src: 'assets/js/tweenmax.js' },
  { name: 'sc_11', src: 'assets/js/slipting.js' },
  { name: 'sc_12', src: 'assets/js/scrollmagic.js' },
  { name: 'sc_13', src: 'assets/js/addindicators.js' },
  { name: 'sc_14', src: 'assets/js/slick.js' },
  { name: 'sc_15', src: 'assets/js/youtube.js' },
  { name: 'sc_16', src: 'assets/js/countdown.js' },
  { name: 'sc_17', src: 'assets/js/scrollup.js' },
  { name: 'sc_18', src: 'assets/js/stickysidebar.js' },
  { name: 'sc_19', src: 'assets/js/contactform.js' },
  { name: 'sc_20', src: 'assets/js/plugins/plugins.min.js' },
  { name: 'sc_21', src: 'assets/js/main.js' }
];

declare var document: any;

@Injectable()
export class DynamicScriptLoaderService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  //IE
            script.onreadystatechange = () => {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    this.scripts[name].loaded = true;
                    resolve({script: name, loaded: true, status: 'Loaded'});
                }
            };
        } else {  //Others
            script.onload = () => {
                this.scripts[name].loaded = true;
                resolve({script: name, loaded: true, status: 'Loaded'});
            };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

}