import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.uat';
import { asLiteral } from '@angular/compiler/src/render3/view/util';


@Component({
  selector: 'public-header',
  templateUrl: './../views/publicheader.component.html',
  styleUrls: ['./../styles/publicheader.component.css']
})

export class PublicHeaderComponent extends BaseComponent {
  isHome: boolean = false;
  urlStr: string = '';

  IsShow: boolean = false;
  @ViewChild("joinforfree") joinforfree: ElementRef;
  @ViewChild("pricing") pricing: ElementRef;
  @ViewChild("howitworks") howitworks: ElementRef;
  @ViewChild("contactus") contactus: ElementRef;

  @ViewChild("li_navbarDropdown") li_navbarDropdown: ElementRef;
  @ViewChild("navbarDropdown") navbarDropdown: ElementRef;
  @ViewChild("dropdown_menu") dropdown_menu: ElementRef;

  @Output() NavigatePage = new EventEmitter();

  constructor(public router: Router,) {
    super(router);
  }

  ngOnInit() {


    this.LoadScripts();

    if (this.router) {
      let urlStr = this.router.url
      if (urlStr.indexOf('?') > -1) {
        urlStr = urlStr.split('?')[0];
      }
      let cleanUrlStr = urlStr.replace(/^\/|\/$/g, '');
      cleanUrlStr = cleanUrlStr.replace('/', '-');
      this.isHome = (cleanUrlStr === 'public');

      let routeUrl = urlStr.split('/');
      this.urlStr = routeUrl[routeUrl.length - 1];
    }

  }

  LoadScripts() {
    this.RemoveScripts();
    this.loadLink("assets/css/vendor/bootstrap.min.css");
    this.loadLink("assets/css/plugins/slick.css");
    this.loadLink("assets/css/plugins/font-awesome.css");
    this.loadLink("assets/css/plugins/icomoon.css");
    this.loadLink("assets/css/plugins/plugins.css");
    this.loadLink("assets/css/style_new.css");
    // this.loadLink("assets/css/style.css");
    // this.loadLink("https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");


    this.loadScript('https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js');
    // this.loadScript('https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js');
    // this.loadScript('https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js');
    //this.loadScript('assets/js/jquery.min.js');
    //this.loadScript('assets/js/vendor/jquery.js');
    //this.loadScript('assets/js/vendor/bootstrap.min.js');
    this.loadScript('assets/js/waypoints.min.js');
    this.loadScript('assets/js/wow.js');
    this.loadScript('assets/js/counterup.js');
    this.loadScript('assets/js/imagesloaded.js');
    this.loadScript('assets/js/isotope.js');
    this.loadScript('assets/js/tilt.js');
    this.loadScript('assets/js/anime.js');
    this.loadScript('assets/js/tweenmax.js');
    this.loadScript('assets/js/slipting.js');
    this.loadScript('assets/js/scrollmagic.js');
    this.loadScript('assets/js/addindicators.js');
    this.loadScript('assets/js/slick.js');
    this.loadScript('assets/js/youtube.js');
    this.loadScript('assets/js/countdown.js');
    this.loadScript('assets/js/scrollup.js');
    this.loadScript('assets/js/stickysidebar.js');
    this.loadScript('assets/js/contactform.js');
    this.loadScript('assets/js/plugins/plugins.min.js');
    this.loadScript('assets/js/main.js');
  }

  public RemoveScripts() {
    const body = <HTMLDivElement>document.body;
    var scripts = body.getElementsByTagName('script');
    var i = scripts.length;
    while (i--) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }

    var links = body.getElementsByTagName('link');
    var j = links.length;
    while (j--) {
      links[j].parentNode.removeChild(links[j]);
    }
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  public loadLink(url: string) {
    const body = <HTMLDivElement>document.body;
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    body.appendChild(link);
  }

  DoMenuNavigate(val) {

    var page = '';
    if (val == 'joinforfree') {
      page = 'business';
    }
    else if (val == 'login') {
      this.router.navigate(['/public/login'])
        .then(() => {
          window.location.reload();
        });
    } else if (val == 'pricing') {
      page = 'business';
    }
    else if (val == 'How-It-Works') {
      page = 'home';
    }
    else if (val == 'home') {
      page = 'home';
      this.router.navigate(['/public'])
        .then(() => {
          window.location.reload();
        });
    }
    else {
      page = 'business';
    }
    var section = '#' + val;
    var combine = page + ',' + section;
    this.NavigatePage.emit(combine);
    return;
    if (val == 'joinforfree') {
      this.router.navigate(['/public/business'])
        .then(() => {
          let scrollToTop = window.setInterval(() => {
            let pos = window.pageYOffset;
            if (pos > 0) {
              window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
              window.clearInterval(scrollToTop);
            }
          }, 16);
        });
    } else if (val == 'login') {
      this.router.navigate(['/public/login'])
        .then(() => {
          window.location.reload();
        });
    }
    else if (val == 'pricing') {
      this.router.navigate(['/public/business'])
        .then(() => {
          this.pricing.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        });
    }
    else if (val == 'howitworks') {
      this.router.navigate(['/public'])
        .then(() => {
          this.howitworks.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        });
    }
    else {
      this.router.navigate(['/public/business'])
        .then(() => {
          this.contactus.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        });
    }

  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  Navigate(val) {
    if (val == 'business') {
      this.router.navigate(['/public/business'])
        .then(() => {
          let scrollToTop = window.setInterval(() => {
            let pos = window.pageYOffset;
            if (pos > 0) {
              window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
              window.clearInterval(scrollToTop);
            }
          }, 16);
        });
    }
    else {
      this.router.navigate(['/public'])
        .then(() => {
          let scrollToTop = window.setInterval(() => {
            let pos = window.pageYOffset;
            if (pos > 0) {
              window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
              window.clearInterval(scrollToTop);
            }
          }, 16);
        });
    }
  }

  Menuclicked() {
    try {
        if (document.getElementById("li_navbarDropdown").classList.contains("show")) {
          setTimeout(
            function () {
              if (document.getElementById("li_navbarDropdown").classList.contains("show")) {
                document.getElementById("li_navbarDropdown").classList.remove("show");
                document.getElementById("dropdown_menu").classList.remove("show");
              }
              else{
                //document.getElementById("li_navbarDropdown").classList.add("show");
                //document.getElementById("navbarDropdown").classList.add("");
                //document.getElementById("dropdown_menu").classList.add("show");
              }
            }, 1000);         
        }
        else {
          setTimeout(
            function () {
              if (document.getElementById("li_navbarDropdown").classList.contains("show")) {
                //document.getElementById("li_navbarDropdown").classList.remove("show");
                //document.getElementById("navbarDropdown").classList.remove("");
                //document.getElementById("dropdown_menu").classList.remove("show");
              }
              else{
                document.getElementById("li_navbarDropdown").classList.add("show");
                document.getElementById("dropdown_menu").classList.add("show");
              }
            }, 1000);        
        }
      
    }
    catch (err) {
      alert(err);
    }
  }
}