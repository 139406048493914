export const environment = {
  production: false,
  baseUrl:'https://dev-api.hagglr.ai/haggler/',
  login:'business/validateuser',
  register:'business/register',
  regConfirm: 'business/register/:token',
  getDeals: 'listdeals?page=:page&limit=:limit&sort=:sort&dir=:dir',
  saveDeals:'createdeal',
  getCatgories:'categories/list',
  getSubCatgories:'category/:category',
  getDeliveryOptionAll: 'deliveryoptions/list',
  getProfile:'business/:emailaddress',
  updateProfile: 'business/updateprofile',
  deleteDeal:'delete/:dealId',
  getDeal: 'getdeal/:dealId',
  businessProfile: 'business/details/:businessID',
  getAllBusiness:'business/list?page=:page&limit=:limit&sort=:sort&dir=:dir',
  businessImageUpload: 'images/uploadb64',
  businessImage: 'images/:imageId',
  toggleDeal: 'deal/:status',
  chatAgentId:'29789414-1359-429f-9a39-0a6a89f3ff3a', 
  chatIconUrl:'https://dev.hagglr.ai/assets/img/final-icon.png',
  saveContacts: 'contacts',
  getContacts: 'contacts/list',
  saveComments:'contacts/:Id',
  paypalAuth:'Basic QVlaY2ZIVG4zbHVpX1F2WGstZ0F6SnB3dDNsN3FkdFItWU14aEJ4ckNsZnByX01JRVZuX1pjV1FvZUVBeHlFaWNlTTdJdG5MU3NuSFUwcHQ6RU1mcTVtY043VGlNME5kOHRxS2pVMmF6U01YcmxMT0ZMY0d6aHVpcUtPN1M4SDBhLW1TSkJDQUhkRzhOdk5rRTR1a3hUdkhtYUFMcWRUMWY=', // LIVE AUTH
  paypalFreePlanID:'P-24041208RB733783TL56APHQ',
  paypalPremiumPlanID:'P-6MB05727FC981221PL4QT2UA',
  updatePayPal:'payments',
  paypalSubscripURL:'https://api.paypal.com/v1/billing/subscriptions/', // LIVE URL
  socialMediaLogin:'business/socialLogin',
  selectedMenu:''
};
