import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { FacebookService } from 'src/app/shared/services/facebook.service';
import { environment } from 'src/environments/environment';
import { DynamicScriptLoaderService } from './shared/services/DynamicScriptLoaderService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Hagglr';
  routerSubscription!: Subscription;

  constructor(public router: Router,
    public _fbSvc: FacebookService, private dynamicScriptLoader: DynamicScriptLoaderService) { }


  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    //this.RemoveScripts()
    //alert('loaded');
    //this.loadScripts();
    // this.loadLink("assets/css/icons.css");
    // this.loadLink("assets/css/animate.css");
    // this.loadLink("assets/css/plugins/font-awesome.css");
    // this.loadLink("assets/css/jquery.datetimepicker.css");

    // this.loadLink("assets/css/vendor/bootstrap.min.css");
    // this.loadLink("assets/css/plugins/slick.css");
    // this.loadLink("assets/css/plugins/font-awesome.css");
    // this.loadLink("assets/css/plugins/icomoon.css");
    // this.loadLink("assets/css/plugins/plugins.css");
    // this.loadLink("assets/css/style.css");

    // this.loadScript('assets/js/vendor/jquery.js');
    // this.loadScript('assets/js/vendor/bootstrap.min.js');
    // this.loadScript('assets/js/waypoints.min.js');
    // this.loadScript('assets/js/wow.js');
    // this.loadScript('assets/js/counterup.js');
    // this.loadScript('assets/js/imagesloaded.js');
    // this.loadScript('assets/js/isotope.js');
    // this.loadScript('assets/js/tilt.js');
    // this.loadScript('assets/js/anime.js');
    // this.loadScript('assets/js/tweenmax.js');
    // this.loadScript('assets/js/slipting.js');
    // this.loadScript('assets/js/scrollmagic.js');
    // this.loadScript('assets/js/addindicators.js');
    // this.loadScript('assets/js/slick.js');
    // this.loadScript('assets/js/youtube.js');
    // this.loadScript('assets/js/countdown.js');
    // this.loadScript('assets/js/scrollup.js');
    // this.loadScript('assets/js/stickysidebar.js');
    // this.loadScript('assets/js/contactform.js');
    // this.loadScript('assets/js/plugins/plugins.min.js');
    // this.loadScript('assets/js/main.js'); 

    // New started
    // this.loadScript('assets/js/jquery.min.js'); 
    // this.loadScript('assets/js/bootstrap.bundle.min.js'); 
    // this.loadScript('assets/js/metisMenu.min.js'); 
    // this.loadScript('assets/js/jquery.slimscroll.js'); 
    // this.loadScript('assets/js/waves.min.js'); 
    // this.loadScript('assets/js/app.js'); 


    // this.loadScript('assets/plugins/datatables/jquery.dataTables.min.js'); 
    // this.loadScript('assets/plugins/datatables/dataTables.bootstrap4.min.js'); 
    // this.loadScript('assets/plugins/datatables/dataTables.buttons.min.js'); 
    // this.loadScript('assets/plugins/datatables/buttons.bootstrap4.min.js'); 
    // this.loadScript('assets/plugins/datatables/vfs_fonts.js');
    // this.loadScript('assets/plugins/datatables/buttons.html5.min.js'); 
    // this.loadScript('assets/plugins/datatables/buttons.colVis.min.js'); 
    // this.loadScript('assets/plugins/datatables/dataTables.responsive.min.js'); 
    // this.loadScript('assets/plugins/datatables/responsive.bootstrap4.min.js'); 
    // this.loadScript('assets/pages/datatables.init.js'); 

    // this.loadScript('assets/plugins/bootstrap-filestyle/js/bootstrap-filestyle.min.js'); 

    // this.loadScript('assets/js/jquery-min.js'); 
    // this.loadScript('assets/js/popper.min.js'); 
    // this.loadScript('assets/js/bootstrap.min.js'); 
    // this.loadScript('assets/js/owl.carousel.min.js'); 
    // this.loadScript('assets/js/wow.js'); 
    // this.loadScript('assets/js/jquery.nav.js'); 
    // this.loadScript('assets/js/scrolling-nav.js'); 
    // this.loadScript('assets/js/jquery.easing.min.js'); 

    // this.loadScript('assets/plugins/chart.js/chart.min.js'); 
    // this.loadScript('assets/plugins/chartist/js/chartist.min.js'); 
    // this.loadScript('assets/plugins/chartist/js/chartist-plugin-tooltip.min.js'); 
    // this.loadScript('assets/pages/chartist.init.js'); 
    // this.loadScript('assets/pages/chartjs.init.js'); 

    // this.loadScript('assets/js/php-date-formatter.js'); 
    // this.loadScript('assets/js/jquery.datetimepicker.js'); 
    // New ended

    // if (environment.production) {
    //   if (location.protocol === 'http:') {
    //     console.log("app.component.ngOnInit()")
    //     window.location.href = location.href.replace('http', 'https');
    //   }
    // }
    // this.routerSubscription = this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe(() => {
    //   window.scrollTo(0, 0);
    // });    

    // this._fbSvc.loadFBSDK();
  }

  public RemoveScripts() {
    const body = <HTMLDivElement>document.body;
    var scripts = body.getElementsByTagName('script');
    var i = scripts.length;
    while (i--) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }

    var links = body.getElementsByTagName('link');
    var j = links.length;
    while (j--) {
      links[j].parentNode.removeChild(links[j]);
    }
  }

  private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader.load('sc_1', 'sc_2', 'sc_3', 'sc_4', 'sc_5', 'sc_6', 'sc_7', 'sc_8', 'sc_9', 'sc_10', 'sc_11', 'sc_12', 'sc_13', 'sc_14', 'sc_15', 'sc_16', 'sc_17', 'sc_18', 'sc_19', 'sc_20', 'sc_21').then(data => {
      // Script Loaded Successfully
    }).catch(error => console.log(error));
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  public loadLink(url: string) {
    const body = <HTMLDivElement>document.body;
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    body.appendChild(link);
  }



  ngOnDestroy() {
    if (this.routerSubscription)
      this.routerSubscription.unsubscribe();
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
}
