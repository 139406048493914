<public-header (NavigatePage)="navigatePageTo($event)"></public-header>
<div class="wrapper-page" style="max-width: 900px;margin-top: 35px;margin-bottom: 0;">
    <div class="card overflow-hidden account-card mx-3" style="margin-bottom:0px;">
        <div class="bg-primary p-4 text-white text-center position-relative">
            <h4 class="font-20 m-b-5">Sign Up to Hagglr</h4>
            <p class="text-white-50 mb-4">Get your Hagglr account now.</p>
            <a class="logo logo-admin"><img src="assets/img/fav-logo.png" height="38" alt="logo" (click)="GoHome()"></a>
        </div>
        <div class="account-card-content">
            <ngb-alert *ngIf="alert.type !=''" [type]="alert.type" (close)="alert.type = ''">{{ alert.message }}
            </ngb-alert>
            <form [formGroup]="registrationForm" (ngSubmit)="registerNewUser()" class="form-horizontal m-t-30"
                action="#">

                <div class="row">
                    <div class="col-md-6 vright-pad">
                        <div class="col-md-12">
                            <div class="form-group">
                                <!-- <input type="text"> -->
                                <input type="email" name="businessemail" placeholder="Business Email"
                                    id="inputBusinessEmail" formControlName="inputBusinessEmail"
                                    [ngClass]="{ 'is-invalid': submitted && f.inputBusinessEmail.errors }">
                                <div *ngIf="submitted && f.inputBusinessEmail.errors" class="invalid-feedback">
                                    <div *ngIf="f.inputBusinessEmail.errors">Please provide a valid business email.
                                    </div>
                                </div>
                                <!-- <label>Business Email</label> -->
                                <span class="focus-border"></span>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <input type="text" name="businessname" placeholder="Business Name"
                                    id="inputBusinessName" formControlName="inputBusinessName"
                                    [ngClass]="{ 'is-invalid': submitted && f.inputBusinessName.errors }">
                                <div *ngIf="submitted && f.inputBusinessName.errors" class="invalid-feedback">
                                    <div *ngIf="f.inputBusinessName.errors.required">Please provide a valid business
                                        name.
                                    </div>
                                </div>
                                <!-- <label>Business Name</label> -->
                                <span class="focus-border"></span>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <!-- <input type="text"> -->
                                <input type="text" data-phone-input-format-dir placeholder="Business Phone"
                                    [actualValue]='f.inputBusinessPhone.value' name="businessphone"
                                    id="inputBusinessPhone" formControlName="inputBusinessPhone"
                                    [ngClass]="{ 'is-invalid': submitted && f.inputBusinessPhone.errors }">
                                <div *ngIf="submitted && f.inputBusinessPhone.errors" class="invalid-feedback">
                                    <div *ngIf="f.inputBusinessPhone.errors">Please provide a valid phone number.
                                    </div>
                                </div>
                                <!-- <label>Business Phone</label> -->
                                <span class="focus-border"></span>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <!-- <input type="text"> -->
                                <input type="text" name="businesszipcode" id="inputBusinessZip"
                                    placeholder="Business Zip Code" formControlName="inputBusinessZip"
                                    [ngClass]="{ 'is-invalid': submitted && f.inputBusinessZip.errors }">
                                <div *ngIf="submitted && f.inputBusinessZip.errors" class="invalid-feedback">
                                    <div *ngIf="f.inputBusinessZip.errors">Please provide a valid zip.
                                    </div>
                                </div>
                                <!-- <label>Business Zip Code</label> -->
                                <span class="focus-border"></span>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <!-- <input type="text"> -->
                                <input type="password" id="inputPassword" placeholder="Password"
                                    formControlName="inputPassword"
                                    [ngClass]="{ 'is-invalid': submitted && f.inputPassword.errors }">
                                <div *ngIf="submitted && f.inputPassword.errors" class="invalid-feedback">
                                    <div *ngIf="f.inputPassword.errors.required">Please provide a valid password.
                                    </div>
                                </div>
                                <!-- <label>Password</label> -->
                                <span class="focus-border"></span>
                            </div>
                        </div>
                        <div class="col-md-12 vbtn7">
                            <!-- <button type="button" class="btn btn-outline btn-info">Sign Up</button> -->
                            <button class="btn btn-primary w-md waves-effect waves-light" [disabled]="apiInProgress"
                                type="submit">Sign Up</button>
                        </div>

                    </div>
                    <div class="col-md-6 vsocial-btn">
                        <div class="social-auth-links text-center">
                            <div class="row">
                                <div class="col-md-12">
                                    <a routerLink="/public/registration"
                                        class="btn btn-block btn-social btn-facebook btn-flat" (click)="loginFb()"><i
                                            class="mdi mdi-facebook"></i> Sign up using Facebook</a>
                                </div>
                                <div class="col-md-12">
                                    <a routerLink="/public/registration"
                                        class="btn btn-block btn-social btn-google btn-flat" #loginRef><i
                                            class="mdi mdi-google-plus"></i> Sign up using Google</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="m-t-10 text-center foot-text">
        <p>Already have an account ? <a routerLink="/public/login" class="font-500 text-primary">Login</a></p>
        <!--<p>&copy; 2018-2021 Hagglr, Inc.</p>-->
    </div>
</div>
<public-footer (NavigatePage)="navigatePageTo($event)"></public-footer>