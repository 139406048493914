<!-- Start Header -->
<header class="ax-header haeder-default light-logo-version header-transparent axil-header-sticky">
  <div class="header-wrapper">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-3 col-md-6 header-left">
          <div class="logo">
            <a class="hand-cursor" (click)="DoMenuNavigate('home')">
              <img src="assets/img/logo.png" class="img-fluid" alt="" />
            </a>
          </div>
        </div>
        <div class="col-lg-9 col-md-6 header-right">
          <div class="mainmenu-wrapepr">

            <!-- End Mainmanu Nav -->
            <div class="axil-header-extra d-flex align-items-center">
              <div class="Business-Owner hand-cursor"><a  class="ndnav" (click)="DoMenuNavigate('business')">Are you a Business Owner?</a>
              </div>
            </div>

            <nav class="navbar navbar-expand-lg navbar-light">
              <div class="navbar-collapse" id="mainMenu">
                <ul class="navbar-nav ml-auto">
                  <li class="nav-item dropdown"  id ="li_navbarDropdown" #li_navbarDropdown>
                    <a class="nav-link hand-cursor" id="navbarDropdown" role="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false" (click)="Menuclicked()"   #navbarDropdown>
                      ooo
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown"   id="dropdown_menu" #dropdown_menu>
                      <a class="dropdown-item smoth-animation hand-cursor" style="color: rgb(0 0 0 / 44%);" #joinforfree (click)="DoMenuNavigate('join-for-free')">Join for free</a>
                      <a class="dropdown-item smoth-animation hand-cursor" style="color: rgb(0 0 0 / 44%);" [routerLink]="['/public/login']">Sign In</a>
                      <a class="dropdown-item smoth-animation hand-cursor" style="color: rgb(0 0 0 / 44%);" #pricing (click)="DoMenuNavigate('pricing')">Pricing</a>
                      <a class="dropdown-item smoth-animation hand-cursor" style="color: rgb(0 0 0 / 44%);" #howitworks (click)="DoMenuNavigate('How-It-Works')">How It Works</a>
                      <a class="dropdown-item smoth-animation hand-cursor" style="color: rgb(0 0 0 / 44%);" #contactus (click)="DoMenuNavigate('contactus')">Contact Us</a>
                    </div>
                  </li>
                </ul>
              </div>
           
            </nav>

          </div>
        </div>
      </div>
    </div>
  </div>
</header>
