import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MemberApi } from 'src/app/shared/services/memberApi';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-forgetpass-email',
  templateUrl: '../views/forgetpass-email.component.html',
  styleUrls: ['../styles/forgetpass-email.component.css']
})
export class ForgetpassEmailComponent extends BaseComponent implements OnInit {
  forgetPassEmailForm!: FormGroup;
  submitted: boolean = false;
  alert: any = { type: '', message: '' }
  constructor(public router: Router,
    public fb: FormBuilder,public _api: MemberApi, public _userSvc: UserService) {
    super(router);
  }

  ngOnInit() {
    this.forgetPassEmailForm = this.fb.group({
      //email: ['developer@gmail.com', [Validators.required, Validators.email]]
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get f() {
    return this.forgetPassEmailForm.controls;
  }

  forgotEmailSubmit() {
    if (this.forgetPassEmailForm.valid) {
      let inputData = {
        emailAddress: this.forgetPassEmailForm.value.email
      };

      this._api.getProfileByEmail(inputData.emailAddress).subscribe(
        (res: any) => {
          console.log(JSON.stringify(res));
          if (res.businessId) {
            this._userSvc.setUserData(res);
            localStorage.setItem('userData', JSON.stringify(res));
            this.alert.type = 'success';
            this.alert.message = 'Login Successful!. Please wait...';
            this.router.navigate(['/public/forgotpassConfirm']);
          } else {
            this.alert.type = 'danger';
            this.alert.message = res.error;
          }
        },
        (error: any) => {
          this.alert.type = 'danger';
          this.alert.message = 'Invalid Email ID';
        });
    }
  }

}
