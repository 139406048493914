import { Component, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/helpers/validation.helper';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { PublicApi } from 'src/app/shared/services/publicApi';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
    templateUrl: './../views/registration.component.html',
    styles: [`
    .verify h5 {
        font-size: 2rem;
        color:#1998d5;
    }
    .btn-google {
        Color: white ! important;
        background-color: #DD4B39;
    border-color: rgba(0,0,0,0.2);
        }
        .btn-facebook{
        Color: white ! important;
        background-color: #3B5998 !important;
    border-color: rgba(0,0,0,0.2);
        }   
        .btn-social {
            position: relative;
            padding-left: 44px;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            padding-bottom: 13px;
            padding-top: 13px;
            margin-bottom: 16px;
        }  
        .btn-facebook:active, .btn-facebook:hover{
            color: #fff !important;
            background-color: #2d4373 !important;
            border-color: rgba(0,0,0,0.2);
        }
        .btn-google:active, .btn-google:hover {
            color: #fff !important;
            background-color: #c23321;
            border-color: rgba(0,0,0,0.2);
        }   
        .bg-primary {
            background-color: #1998d5!important;
        }
        .text-primary {
            color: #6c9ec3!important;
        }
        .btn-outline-primary.active, .btn-outline-primary:active, .btn-outline-primary:hover, .btn-outline-primary:not([disabled]):not(.disabled).active, .btn-outline-primary:not([disabled]):not(.disabled):active, .btn-primary.active, .btn-primary.focus, .btn-primary:active, .btn-primary:focus, .btn-primary:hover, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .open>.dropdown-toggle.btn-primary, .show>.btn-outline-primary.dropdown-toggle, .show>.btn-primary.dropdown-toggle {
            background-color: #1578bd;
            border: 1px solid #1578bd;
        }
        a.text-primary:focus, a.text-primary:hover {
            color: #1998d5!important;
        }
    `]
})

export class RegistrationComponent extends BaseComponent {
    public registrationForm!: FormGroup;
    submitted: boolean = false;
    apiInProgress: boolean = false;
    showRegFormSection: boolean = true;
    showEmailSection: boolean = false;
    alert: any = { type: '', message: '' }
    auth2: any;

    @ViewChild('loginRef') loginElement!: ElementRef;

    constructor(public fb: FormBuilder,
        public router: Router,
        public _api: PublicApi,
        public _userSvc: UserService, public zone: NgZone) {
        super(router);
    }

    onSuccessReg(event: Event) {
        this.router.navigate(['/public/login']);
    }

    ngOnInit() {
        //this.LoadScripts();
        this.registrationForm = this.fb.group({
            inputName: [''],
            inputPassword: ['', Validators.required],
            inputBusinessName: ['', Validators.required],
            inputBusinessEmail: ['', [Validators.required, Validators.email]],
            inputBusinessPhone: ['', [Validators.required, CustomValidator.phoneValidator]],
            inputBusinessZip: ['', [Validators.required, CustomValidator.zipCodeValidator]]
        });

        this.googleSDK();
        this.fbLibrary();
    }

    LoadScripts() {
        this.RemoveScripts();
        this.loadLink("assets/css/bootstrap.min.css");
        this.loadLink("assets/css/metismenu.min.css");
        this.loadLink("assets/css/icons.css");
        this.loadLink("assets/css/style.css");


        this.loadScript('assets/js/jquery.min.js');
        this.loadScript('assets/js/bootstrap.bundle.min.js');
        this.loadScript('assets/js/metisMenu.min.js');
        this.loadScript('assets/js/jquery.slimscroll.js');
        this.loadScript('assets/js/waves.min.js');
        this.loadScript('assets/js/app.js');
    }

    public RemoveScripts() {
        const body = <HTMLDivElement>document.body;
        var scripts = body.getElementsByTagName('script');
        var i = scripts.length;
        while (i--) {
            scripts[i].parentNode.removeChild(scripts[i]);
        }

        var links = body.getElementsByTagName('link');
        var j = links.length;
        while (j--) {
            links[j].parentNode.removeChild(links[j]);
        }
    }

    public loadScript(url: string) {
        const body = <HTMLDivElement>document.body;
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = true;
        body.appendChild(script);
    }

    public loadLink(url: string) {
        const body = <HTMLDivElement>document.body;
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = url;
        body.appendChild(link);
    }

    get f() {
        return this.registrationForm.controls;
    }

    prepareReqObj() {
        return {
            "businessId": null,
            "businessName": this.registrationForm.value.inputBusinessName,
            "emailAddress": this.registrationForm.value.inputBusinessEmail,
            "password": this.registrationForm.value.inputPassword,
            "firstName": this.registrationForm.value.inputName,
            "lastName": null,
            "phone": this.registrationForm.value.inputBusinessPhone ? this.registrationForm.value.inputBusinessPhone.split('-').join('') : null,
            "zipCode": this.registrationForm.value.inputBusinessZip,
            "latitude": null,
            "longitude": null,
            "businessImage": null,
            "fax": null,
            "category": null,
            "subCategory": null,
            "businessUrl": null,
            "streetAddress": null,
            "city": null,
            "state": null,
            "status": "Active" //TODO: Change this to In Active
        }
    }

    registerNewUser() {
        this.submitted = true;
        if (this.registrationForm.valid) {
          this.apiInProgress = true;
          this._api.register(this.prepareReqObj()).subscribe(
            (res: any) => {
              if (res.businessId) {
                this._userSvc.setUserData(res);
                localStorage.setItem('userData', JSON.stringify(res));
                this.alert.type = 'success';
                this.alert.message = 'Registration Successful!. Please wait...';
                setTimeout(() => {
                  this.zone.run(() => this.router.navigateByUrl('/member/updateProfile'));
                }, 500);
              } else {
                this.alert.type = 'danger';
                this.alert.message = res.error;
                this.apiInProgress = false;
              }
            },
            (error: any) => {
              this.alert.type = 'danger';
              this.alert.message = 'Please check the input data';
              this.apiInProgress = false;
            });
        }
      }

    GoHome() {
        this.router.navigate(['/public'])
            .then(() => {
                window.location.reload();
            });
    }
    /**
    * Google Login
    */

    prepareLoginButton() {

        this.auth2.attachClickHandler(this.loginElement.nativeElement, {},
            (googleUser: any) => {

                let profile = googleUser.getBasicProfile();
                // console.log('Token || ' + googleUser.getAuthResponse().id_token);
                // console.log('ID: ' + profile.getId());
                // console.log('Name: ' + profile.getName());
                // console.log('Image URL: ' + profile.getImageUrl());
                // console.log('Email: ' + profile.getEmail());
                //YOUR CODE HERE
                let postdata = {
                    "emailAddress": profile.getEmail(),
                    "password": "",
                    "source": "google",
                    "businessName": profile.getName(),
                    "city": ""
                }
                this._api.socialMediaLogin(postdata).subscribe((res: any) => {
                    if (res.businessId) {
                        this._userSvc.setUserData(res);
                        localStorage.setItem('userData', JSON.stringify(res));
                        this.alert.type = 'success';
                        this.alert.message = 'Login Successful!. Please wait...';
                        //  this.router.navigate(['/member/dashboard']);
                        this.zone.run(() => this.router.navigateByUrl('/member/dashboard'));
                    } else {
                        this.alert.type = 'danger';
                        this.alert.message = res.error;
                    }
                }, (err: any) => { })

            }, (error: any) => {
                alert(JSON.stringify(error, undefined, 2));
            });

    }
    googleSDK() {

        window['googleSDKLoaded'] = () => {
            window['gapi'].load('auth2', () => {
                this.auth2 = window['gapi'].auth2.init({
                    client_id: '528597511411-3trmul0klftl806it793fhb1644q2m7b.apps.googleusercontent.com',
                    cookiepolicy: 'single_host_origin',
                    scope: 'profile email'
                });
                this.prepareLoginButton();
            });
        }

        (function (d, s, id) {
            var js: any, fjs: any = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-jssdk'));

    }
    /**
     * FB Login
     */
    loginFb() {
        window['FB'].login((response: { authResponse: any; }) => {
            // console.log('login response', response);
            if (response.authResponse) {

                window['FB'].api('/me', {
                    fields: 'last_name, first_name, email'
                }, (userInfo: { email: any; first_name: string; last_name: string; }) => {

                    //  console.log("user information");
                    //  console.log(userInfo);
                    let postdata = {
                        "emailAddress": userInfo.email,
                        "password": "",
                        "source": "facebook",
                        "businessName": userInfo.first_name + ' ' + userInfo.last_name,
                        "city": ""
                    }
                    this._api.socialMediaLogin(postdata).subscribe((res: any) => {
                        if (res.businessId) {
                            this._userSvc.setUserData(res);
                            localStorage.setItem('userData', JSON.stringify(res));
                            this.alert.type = 'success';
                            this.alert.message = 'Login Successful!. Please wait...';
                            // this.router.navigate(['/member/dashboard']);
                            this.zone.run(() => this.router.navigateByUrl('/member/dashboard'));
                        } else {
                            this.alert.type = 'danger';
                            this.alert.message = res.error;
                        }
                    }, (err: any) => { })
                });

            } else {
                // console.log('User login failed');
            }
        }, { scope: 'email' });
    }

    fbLibrary() {

        (window as any).fbAsyncInit = function () {
            window['FB'].init({
                appId: '3819439784750389',
                cookie: true,
                xfbml: true,
                version: 'v3.1'
            });
            window['FB'].AppEvents.logPageView();
        };

        (function (d, s, id) {
            var js: any, fjs: any = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    navigatePageTo(element: string) {
        //alert('home - ' + element);
        var str_split = element.split(',');
        if (str_split[0] == 'business') {
          //alert(str_split[1] + 'navigatePageTo');
          this.router.navigate(['/public/business'])
            .then(() => {
              setTimeout(() => {
                document.querySelector(str_split[1]).scrollIntoView({ behavior: "auto"});
              }, 2000);
            });
        }
        else if (str_split[0] == 'home') {
          this.router.navigate(['/public'])
            .then(() => {
              setTimeout(() => {
                document.querySelector(str_split[1]).scrollIntoView({ behavior: "auto"});
              }, 1000);
            });
          //document.querySelector(str_split[1]).scrollIntoView({ behavior: "smooth", block: "start" });
        }
        else {
        }
      }
}
