import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, FormArray, Validators,FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MemberApi } from 'src/app/shared/services/memberApi';
import { MatNativeDateModule } from "@angular/material/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UpdateProfileComponent } from '../updateprofile.component';

@Component({
  selector: 'delivery-option',
  templateUrl: './delivery-option.component.html'
})
export class DeliveryOptionComponent implements OnInit {

  _form: FormGroup;
  deliveryOptionsAll = [];
  passingCount : number=0;
  @Input('delivery') availableDelivOption : any[];
  @Output() deliverychanel : EventEmitter<any[]> = new EventEmitter<any[]>();

  del_selection:{ deliveryName: string , deliveryUrl : string  }[] =[
                                                     { "deliveryName": 'doordash', "deliveryUrl": "https://doordash/id=333" },
                                                     { "deliveryName": 'ubereats', "deliveryUrl": "https://Ubereats/id=9" }
                                                    ];

  selectedLevel: boolean[] ;

  userDelivery: any;

  
  showDeliveryError : boolean = false;
  

  // deligery url text field name
  txtDeliveryUrl : string ="";
  lstDeliveryName : string = "";
  

  constructor(private _fb: FormBuilder,
		private _api: MemberApi) {    

  }

  ngOnInit() {
	this.loadDeliveryOption();
	this._buildForm();
	}  

  private _buildForm() {
    this._form = this._fb.group({ deliveryOptions: this._fb.array([]) });  
    this._buildFormControl();
    //console.log("selected option count ::: "+this.getControls.length);
    this.selectedLevel= new Array<boolean>(this.getControls.length);

  }


  private _buildFormControl() {
    //console.log("previous selected delivery option length="+ this.availableDelivOption);
   
    if(this.availableDelivOption != null){
    for(let delv of this.availableDelivOption){
	const formArray: FormArray = this._form.get("deliveryOptions") as FormArray;
	    //console.log(delv.deliveryName+" <=dname,durl=> "+delv.deliveryUrl);
	    const form = this._fb.group({
		deliveryName: [delv.deliveryName, Validators.required],
		deliveryUrl: [delv.deliveryUrl, Validators.required]
		});
	formArray.push(form);
    }
    }
    
  }

  getControls() {
	return (this._form.get('deliveryOptions') as FormArray);
	}

  
  checkDeliverySelection(event : any){

	this.showDeliveryError = (event.target[event.target.selectedIndex].value != "")? true:false;
	
		this.lstDeliveryName = event.target[event.target.selectedIndex].value;
	

  }


  setDisplay(i: string, event: any) {
    this.selectedLevel[i] =  (event.target["selectedIndex"] > 0)?false:true;
    this.validate();
  }

  firstLoad() {
    this._addRow();
    this.selectedLevel[0] = true;
  }

loadDeliveryOption(){
	this._api.getDeliveryOptionAll().subscribe(
		(res: any) => {			
		this.deliveryOptionsAll = res;
		}
	);
}

  _addRow() {

    if(this.lstDeliveryName != "" && this.txtDeliveryUrl != ""){
    const formArray: FormArray = this._form.get("deliveryOptions") as FormArray;
    const form = this._fb.group({      
      deliveryName: [this.lstDeliveryName, Validators.required],
      deliveryUrl: [this.txtDeliveryUrl, Validators.required]
    });
	
    formArray.push(form); 
    this.validate();
    }
   
  }

  
  _removeRow(index: number) {
    const formArray: FormArray = this._form.get("deliveryOptions") as FormArray;
    formArray.removeAt(index);
   // if(formArray.length <1){
	//this.firstLoad();	
   // }
    this.validate();   
  }

  // not used
  getDeliveryImage( deliveryName: string){   

   let deliveryImageId = '../../../../assets/images/Phone.png';

  if(deliveryName != null && this.deliveryOptionsAll != null){
	for(let deliv of this.deliveryOptionsAll){
	
		if(deliveryName == deliv.deliveryName){
			deliveryImageId = deliv.imageName;
		}
	}
  }
  }

  
  validate() {

	//console.log("arr obj sending to parent:"+this._form.get("deliveryOptions").value);
	this.deliverychanel.emit(this._form.get('deliveryOptions').value);
	
  }

}