<footer>
    <div class="footer-links">
        <a href="#/public/terms">Terms &amp; Conditions</a>
        <a href="#/public/privacypolicy">Privacy Policy</a>
        <a href="#/public/pricing">Pricing</a>
        <a href="#">Blog</a>
        <a href="#/public/contact">Contact us</a>
    </div>
    <div class="footer-contact-links">
        <a href="tel:+1 925-237-1069">+1 925-237-1069</a>/<a href="mailto:support@hagglr.ai">support@hagglr.ai</a>
    </div>
    <div class="footer-social-links">
        <a href="#fb"><img src="assets/img/fb-logo.png"></a>
        <a href="#tw"><img src="assets/img/twitter-logo.png"></a>
        <a href="#"><img src="assets/img/insta-logo.png"></a>
        <a href="#"><img src="assets/img/linkedin-logo.png"></a>
        <a href="#"><img src="assets/img/pinterest-icon.png"></a> 
        <a href="#"><img src="assets/img/reddit-icon.png"></a> 
        <a href="#"><img src="assets/img/tumblr-icon.png"></a> 
        </div>
        <div class="footer-copyrights">
            <p>&copy; 2018-2021 Hagglr, Inc.</p>
        </div>
    </footer>