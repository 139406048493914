

<public-header></public-header>
<div class="wrapper-page" style="max-width: 900px;margin-top: 35px;margin-bottom: 0;">
	<div class="card overflow-hidden account-card mx-3" style="margin-bottom:0px;">
		<div class="bg-primary p-4 text-white text-center position-relative">
			<h4 class="font-20 m-b-5">Forget password</h4>
			<p class="text-white-50 mb-4">Get your Hagglr account credentials.</p>
			<a class="logo logo-admin"><img src="assets/img/fav-logo.png" height="38" alt="logo"></a>
		</div>
    <div class="account-card-content">
      <ngb-alert *ngIf="alert.type!=''" [type]="alert.type" (close)="alert.type = '' ">{{ alert.message }}</ngb-alert>
      <form (ngSubmit)="forgotEmailSubmit()" [formGroup]="forgetPassEmailForm" class="form-horizontal m-t-30"
        method="post">
        <div class="form-group">
          <input type="text" class="form-control" name="email" placeholder="Enter Business Email" 
            formControlName="email" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required || f.email.errors.email">Please provide a valid email.
            </div>
          </div>
        </div>
        <div class="form-group row m-t-20">
          <div class="col-sm-12 text-right new-align">
            <button class="btn btn-outline btn-info btn btn-primary w-md waves-effect waves-light new-sub" 
              type="submit">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<public-footer></public-footer>
