import { Component, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { trigger, transition, state, animate, style, AnimationEvent } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PublicApi } from 'src/app/shared/services/publicApi';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { switchMap } from 'rxjs/operators';


@Component({
  templateUrl: './../views/home.component.html',
  styles: [`
  .reduce-margin{
    margin-top:-20px;
   }
 `],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(5000, style({ opacity: 1 }))
      ])
    ]),
  ]
})
export class HomeComponent extends BaseComponent {

  @ViewChild("pricing", { static: false }) pricing;
  @ViewChild("contactus", { static: false }) contactus;
  scrollsection: any = '';

  title = 'hagglr';
  userLoggedIn: boolean = false;
  userName: string = 'User';
  refContact: any;
  IsVideoClicked: boolean = false;
  @ViewChild('backToTop') backToTop: any;
  isEnteredCategoryOne: boolean = false;
  isEnteredCategoryTwo: boolean = false;
  isEnteredCategoryThree: boolean = false;
  isEnteredCategoryFour: boolean = false;
  isEnteredCategoryFive: boolean = false;
  isEnteredCategorySix: boolean = false;

  customOptions: OwlOptions = {
    //autoWidth: true,
    loop: true,
    items: 1,
    // margin: 10,
    // slideBy: 'page',
    // merge: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    autoplaySpeed: 2000,
    dotsSpeed: 500,
    animateOut: 'zoomOutLeft',
    animateIn: 'zoomInRight',
    dots: true,
    // dotsData: true,
    // mouseDrag: false,
    // touchDrag: false,
    // pullDrag: false,
    smartSpeed: 2000,
    // fluidSpeed: 499,
    dragEndSpeed: 350,
    nav: false
  }

  constructor(public router: Router,
    private _route: ActivatedRoute,
    private _api: PublicApi, private modalService: NgbModal) {
    super(router);
  }

  ngOnInit() {
    //debugger;
    //this.LoadScripts();
    //window.addEventListener('scroll', this.scroll, true); //third parameter
    let userData = localStorage.getItem('userData');
    if (userData && typeof userData === "object") {
      this.userLoggedIn = true;
      let userData = JSON.parse(localStorage.getItem('userData')!);
      this.userName = userData.firstName;
    }

    // this._route.queryParams.subscribe(params => {
    //   this.refContact = params['s'];
    //   if (this.refContact === 'contact') {
    //     this.scrollToContact();
    //   }
    // });

    this.loadDialogFlowWeb();

  }

  LoadScripts() {
    this.RemoveScripts();
    this.loadLink("assets/css/vendor/bootstrap.min.css");
    this.loadLink("assets/css/plugins/slick.css");
    this.loadLink("assets/css/plugins/font-awesome.css");
    this.loadLink("assets/css/plugins/icomoon.css");
    this.loadLink("assets/css/plugins/plugins.css");
    // this.loadLink("assets/css/style.css");
    this.loadLink("assets/css/style_new.css");

    //this.loadScript('https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js');
    //this.loadScript('https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js');
    this.loadScript('https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js');
    //this.loadScript('assets/js/vendor/bootstrap.min.js');
    this.loadScript('assets/js/waypoints.min.js');
    this.loadScript('assets/js/wow.js');
    this.loadScript('assets/js/counterup.js');
    this.loadScript('assets/js/imagesloaded.js');
    this.loadScript('assets/js/isotope.js');
    this.loadScript('assets/js/tilt.js');
    this.loadScript('assets/js/anime.js');
    this.loadScript('assets/js/tweenmax.js');
    this.loadScript('assets/js/slipting.js');
    this.loadScript('assets/js/scrollmagic.js');
    this.loadScript('assets/js/addindicators.js');
    this.loadScript('assets/js/slick.js');
    this.loadScript('assets/js/youtube.js');
    this.loadScript('assets/js/countdown.js');
    this.loadScript('assets/js/scrollup.js');
    this.loadScript('assets/js/stickysidebar.js');
    this.loadScript('assets/js/contactform.js');
    this.loadScript('assets/js/plugins/plugins.min.js');
    this.loadScript('assets/js/main.js');
  }

  public RemoveScripts() {
    const body = <HTMLDivElement>document.body;
    var scripts = body.getElementsByTagName('script');
    var i = scripts.length;
    while (i--) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }

    var links = body.getElementsByTagName('link');
    var j = links.length;
    while (j--) {
      links[j].parentNode.removeChild(links[j]);
    }
  }

  public RemoveDFMessenger() {
    const body = <HTMLDivElement>document.body;
    var scripts = body.getElementsByTagName('df-messenger');
    var i = scripts.length;
    while (i--) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }

  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  public loadLink(url: string) {
    const body = <HTMLDivElement>document.body;
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    body.appendChild(link);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  public loadScriptOld() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/scripts/slider-init.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  public scroll(event: any) {
    const number = event.srcElement.scrollTop;
    /*if (number > 50) {
      document.getElementsByClassName('scrolling-navbar')[0].classList.add('top-nav-collapse');
      
      let b2t_arrow = this.backToTop.querySelector('#arrow-up');
      if (number > 200) {
        b2t_arrow.style.display = 'block';
      } else {
        b2t_arrow.style.display = 'none';
      }
    } else {
      document.getElementsByClassName('scrolling-navbar')[0].classList.remove('top-nav-collapse');
    }*/

  }

  public scrollToContact() {
    const contactRefElement = document.querySelectorAll('#contact')[0];
    contactRefElement.scrollIntoView({ behavior: 'smooth' });
  }

  public gotoRegister(type: any) {
    console.log(type);
    this.router.navigate(['public/registration']);
  }

  public loadDialogFlowWeb() {
    const dfmElem = document.querySelector<HTMLInputElement>('df-messenger');
    if (dfmElem) {
      dfmElem.style.display = 'block';
    } else {
      var s = document.createElement("script"); s.type = "text/javascript"; s.async = true;
      s.src = "https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1";
      let df = document.createElement("df-messenger");
      df.setAttribute("chat-title", "Hagglr AI");
      //console.log(this._api.getChatIconURL());
      df.setAttribute("chat-icon", this._api.getChatIconURL());
      //df.setAttribute("chat-icon", "https://geservetechnology.com/ref/v9/hagglr/assets/img/hagglr-ai.png");
      //https://haggler-api-tst.uc.r.appspot.com/haggler/assets/img/hagglr-ai.png

      df.setAttribute("agent-id", this._api.getAgentID());
      df.setAttribute("language-code", "en");
      var h = document.getElementsByTagName("body")[0];
      h.appendChild(s);
      h.appendChild(df);
    }

  }

  videoclick() {
    this.IsVideoClicked = true;
  }
  onAnimationEvent(event: AnimationEvent) {
  }
  open(content) {
    const modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    modalRef.componentInstance.name = 'Hagglr';
  }

  navigatePageTo(element: string) {
    //alert('home - ' + element);
    var str_split = element.split(',');
    if (str_split[0] == 'business') {
      //alert(str_split[1] + 'navigatePageTo');
      this.router.navigate(['/public/business'])
        .then(() => {
          setTimeout(() => {
            document.querySelector(str_split[1]).scrollIntoView({ behavior: "auto"});
          }, 2000);
        });
    }
    else if (str_split[0] == 'home') {
      this.router.navigate(['/public'])
        .then(() => {
          setTimeout(() => {
            document.querySelector(str_split[1]).scrollIntoView({ behavior: "auto"});
          }, 1000);
        });
      //document.querySelector(str_split[1]).scrollIntoView({ behavior: "smooth", block: "start" });
    }
    else {
    }
  }

}