<!-- Start Pricing Table Area -->
<div id="pricing" class="axil-pricing-table-area pricing-shape-position ax-section-gap bg-color-lightest">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title text-center">
                    <h2 class="title wow" >Plans &   <span class="bg-all">Pricing</span></h2>
    <p class="subtitle-2 wow words chars splitting animated">There are millions of small businesses impacted due to COVID situation. We want to help them to get back on track. We are happy to announce free subscription.</p>
                </div>
            </div>
        </div>
        <div class="row mt--20 row--30 mt_sm--0">
            <!-- Start Single pricing table -->
            <div class="col-lg-5 col-md-6 col-sm-12 col-12 offset-lg-1">
                <div class="axil-pricing-table mt--40 active move-up wow">
                    <div class="axil-pricing-inner">
                        <div class="pricing-header">
                            <div class="price-wrapper">
                                <div class="price">
                                    <h2 class="heading headin-h3">Pro</h2>
                                </div>
                                <div class="date-option">
                                    <a class="axil-button btn-large btn-transparent" href="/#/public/registration">
          <span class="button-text">Sign Up for Free</span></a>
                                </div>
                            </div>
                            <div class="pricing-get-button">
                                
                            </div>
                        </div>
                        <div class="pricing-body">
                            <div class="inner">
                                <ul class="list-style">
                                    <li>Post Unlimited Deals</li>
                                    <li>Facebook Messenger</li>
                                    <li>Slack</li>
                                    <li>Alexa</li>
                                    <li>Google Assistant</li>
                                    <li>Viber, Telegram and Whatsapp</li>
                                    <li>Bixby (Samsung Devices)</li>
                                    <li>Integration with DoorDash, UberEats, PostMates and Grubhub</li>
                                    <li>Top of the list</li>
                                    <li>Rewards Points</li>
                                    <li>Post Deals only to the Loyal and Repeat customers</li>
                                    <li>Option to Purchase Deals, Make Reservation, Book Appointments and much more</li>
                                    <li>Auto generated deals using AI</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Single pricing table -->

            <!-- Start Single pricing table -->
            <div class="col-lg-5 col-md-6 col-sm-12 col-12 offset-lg-1">
                <div class="axil-pricing-table mt--40 active move-up wow">
                    <div class="axil-pricing-inner">
                        <div class="pricing-header">
                            <div class="price-wrapper">
                                <div class="price">
                                    <h2 class="heading headin-h3">Premium</h2>
                                </div>
                                <div class="date-option">
                                    <a class="axil-button btn-large btn-transparent" href="/#/public/contact">
          <span class="button-text">Contact Sales</span></a>
                                </div>
                            </div>
                            <div class="pricing-get-button">
                                
                            </div>
                        </div>
                        <div class="pricing-body">
                            <div class="inner">
                                <ul class="list-style">
                                    <li>Post Unlimited Deals</li>
                                    <li>Facebook Messenger</li>
                                    <li>Slack</li>
                                    <li>Alexa</li>
                                    <li>Google Assistant</li>
                                    <li>Viber, Telegram and Whatsapp</li>
                                    <li>Bixby (Samsung Devices)</li>
                                    <li>Integration with DoorDash, UberEats, PostMates and Grubhub</li>
                                    <li>Top of the list</li>
                                    <li>Rewards Points</li>
                                    <li>Post Deals only to the Loyal and Repeat customers</li>
                                    <li>Option to Purchase Deals, Make Reservation, Book Appointments and much more</li>
                                    <li>Auto generated deals using AI</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Single pricing table -->
        </div>
    </div>
    
</div>
<!-- End Pricing Table Area -->

<!-- 
<public-header></public-header>
<section id="pricing" class="top-section">
    <div class="container">
        <div class="section-header text-center">
            <h2 class="section-title wow fadeInDown" data-wow-delay="0.3s">Plans & Pricing</h2>
            <div class="shape wow fadeInDown" data-wow-delay="0.3s"></div>
        </div>
        
        <p>There are millions of small businesses impacted due to COVID situation.
            We want to help them to get back on track. We are happy to announce 12 months of free subscription. 
            You can cancel anytime!!! </p>
        <table class="pricingTable">
            <thead>
                <tr>
                    <th></th>
                    <td data-plan="Basic (free )">
                        <h2>Pro</h2>
						<h3>(Free for 1 Year)</h3>
                        <span class="notes">99/Month</span>
                        <a (click)="gotoRegister('basic')" class="btn btn-common mt-3 fff btn-rounded pl-4 pr-4 pt-2 pb-2">Subscribe Now</a>
                    </td>
                    <td data-plan="Premium">
                        <h2>Premium</h2>
						<h3>(Free for 1 Year)</h3>
                        <span class="notes">Contact Sales</span>
						<a class="btn btn-common mt-3 fff btn-rounded pl-4 pr-4 pt-2 pb-2"  style="cursor: not-allowed;">Coming Soon</a>
                        
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>
                        <h3>Post Unlimited Deals</h3>
                    </th>
                    <td data-plan="Basic" class="check">
                        <span></span>
                    </td>
                    <td data-plan="Pro" class="check">
                        <span>Yes</span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <h3>Facebook Messenger</h3>
                    </th>
                    <td data-plan="Basic" class="check">
                        <span></span>
                    </td>
                    <td data-plan="Pro" class="check">
                        <span>Yes</span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <h3>Slack</h3>
                    </th>
                    <td data-plan="Basic" class="check">
                        <span></span>
                    </td>
                    <td data-plan="Pro" class="check">
                        <span>Yes</span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <h3>Alexa</h3>
                    </th>
                    <td data-plan="Basic" class="check">
                        <span></span>
                    </td>
                    <td data-plan="Pro" class="check">
                        <span>Yes</span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <h3>Google Assistant</h3>
                    </th>
                    <td data-plan="Basic" class="check">
                        <span></span>
                    </td>
                    <td data-plan="Pro" class="check">
                        <span>Yes</span>
                    </td>
                </tr>

                <tr>
                    <th>
                        <h3>Viber, Telegram and Whatsapp</h3>
                    </th>
                    <td data-plan="Basic" class="x">
                        <span></span>
                    </td>
                    <td data-plan="Pro" class="check">
                        <span>Yes</span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <h3>Bixby (Samsung Devices)</h3>
                    </th>
                    <td data-plan="Basic" class="x">
                        <span></span>
                    </td>
                    <td data-plan="Pro" class="check">
                        <span>Yes</span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <h3> Integration with DoorDash, UberEats, PostMates and Grubhub</h3>
                    </th>
                    <td data-plan="Basic" class="x">
                        <span></span>
                    </td>
                    <td data-plan="Pro" class="check">
                        <span></span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <h3>Top of the list</h3>
                    </th>
                    <td data-plan="Basic" class="x">
                        <span></span>
                    </td>
                   
                    <td data-plan="Pro" class="check">
                        <span></span>
                    </td>
                </tr>
                <tr>
                    <th>
                        <h3>Rewards Points</h3>
                    </th>
                    <td data-plan="Basic" class="x">
                        <span></span>
                    </td>
                   
                    <td data-plan="Pro" class="check">
                        <span></span>
                    </td>
                   
                </tr>
                <tr>
                    <th>
                        <h3>Post Deals only to the Loyal and Repeat customers</h3>
                    </th>
                    <td data-plan="Basic" class="x">
                        <span></span>
                    </td>
                   
                    <td data-plan="Pro" class="check">
                        <span></span>
                    </td>
                   
                </tr>
                <tr>
                    <th>
                        <h3>Option to Purchase Deals, Make Reservation, Book Appointments and much more</h3>
                    </th>
                    <td data-plan="Basic" class="x">
                        <span></span>
                    </td>
                   
                    <td data-plan="Pro" class="check">
                        <span></span>
                    </td>
                   
                </tr>
                <tr>
                    <th>
                        <h3>Auto generated deals using AI</h3>
                    </th>
                    <td data-plan="Basic" class="x">
                        <span></span>
                    </td>
                    <td data-plan="Pro" class="check">
                        <span></span>
                    </td>
                </tr>
                
            </tbody>
        </table>
    </div>
</section>
<public-footer></public-footer>
-->