<public-header (NavigatePage)="navigatePageTo($event)"></public-header>
<main class="page-wrapper">
    <div class="container">
        <h4 class="axil-display-1 Looking-for-the-best wow slideFadeInUp" data-wow-duration="1s" data-wow-delay="500ms">Looking for the best deals and save money? Just Ask <span class="bg-all">Hagglr!</span></h4>
        <div class="wow slideFadeInUp Hagglr-is-available" data-wow-duration="1s" data-wow-delay="800ms"><span class="bg-all">Hagglr</span> is available in all the messaging channels. 
        Click your favorite messaging channel for the <span class="bg-all">Hagglr</span> Chatbot</div>
        
        <div class="hey-google">
            <div class="col-md-6"><div class="vgoogle-pop"><img src="assets/img/hey-google.jpg" class="img-responsive"></div></div>
            <div class="col-md-6"><div class="vgoogle-pop1"><img src="assets/img/alexa-msg.jpg" class="img-responsive"></div></div>
        </div>
    </div>
 
    <div class="axil-slider-area axil-slide-activation">
        <!-- Start Single Slide -->
        <div class="axil-slide slide-style-default theme-gradient slider-fixed-height d-flex align-items-center paralax-area">
            <div class="container">
                <div class="row align-items-center pt_md--60 mt_sm--60">
                    <div class="vtop-sec">
                        <div class="main-banner-features">
                            <div class="mbf-tooltip-wrap mbf-tooltip-left thumb wow paralax-image animated">
                                <a (click)="open(content)"><img src="assets/img/google.png" class="img-responsive"></a>
                            </div>
                            <div class="mbf-tooltip-wrap mbf-tooltip-left without-background">
                                <a href="https://hagglr-workspace.slack.com/archives/D01AV7YFUES"><img src="assets/img/slack.png" class="img-responsive"></a>
                            </div>
                            <div class="mbf-tooltip-wrap mbf-tooltip-left without-background">
                                <a (click)="open(content)"><img src="assets/img/kik.png" class="img-responsive"></a>
                            </div>
                            <div class="mbf-tooltip-wrap mbf-tooltip-left without-background">
                                <a (click)="open(content)"><img src="assets/img/viber.png" class="img-responsive"></a>
                            </div> 
                            <div class="mbf-tooltip-wrap mbf-tooltip-left without-background">
                                <a href="https://m.me/hagglrbot"><img src="assets/img/fb.png" class="img-responsive"></a>
                            </div>
                            <div class="mbf-tooltip-wrap mbf-tooltip-left without-background">
                                <a href="https://wa.me/19252225108"><img src="assets/img/whatsapp.png" class="img-responsive"></a>
                            </div>
                            <div class="mbf-tooltip-wrap mbf-tooltip-left without-background">
                                <a (click)="open(content)"><img src="assets/img/sms.png" class="img-responsive"></a>
                            </div>
                            <div class="mbf-tooltip-wrap mbf-tooltip-left without-background">
                                <a (click)="open(content)"><img src="assets/img/telegram.png" class="img-responsive"></a>
                            </div>
                            <div class="mbf-tooltip-wrap mbf-tooltip-left without-background alexa-sp">
                                <a (click)="open(content)"><img src="assets/img/alexa.png" class="img-responsive"></a>
                            </div>
                        </div>
                    </div>
                    <div class="dhide"><img src="assets/img/banner.png" class="img-responsive"></div>
                    <div class="col-lg-5 col-12 order-1 order-lg-2">
                        <div class="topskew-thumbnail-group text-left msg-box text-lg-right">
                            <div class="shape-group">
                                <div class="paralax--12">
                                    <img src="assets/img/msg.png" alt="">
                                </div>
                            </div>
                            <div class="row vhome-box">
                                <div class="col-md-2">
                                    <div class="vicon-box counter-first move-up wow animate">
                                        <div class="icon">
                                            <img src="assets/img/restaurant-icon.png" alt="Shape Images">
                                            <span class="vno-box">15</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="vicon-box counter-first move-up wow animate">
                                        <div class="icon">
                                            <img src="assets/img/travel-icon.png" alt="Shape Images">
                                            <span class="vno-box">30</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="vicon-box counter-first move-up wow animate">
                                        <div class="icon">
                                            <img src="assets/img/spa-stone.png" alt="Shape Images">
                                            <span class="vno-box">34</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="vicon-box counter-first move-up wow animate">
                                        <div class="icon">
                                            <img src="assets/img/yoga-icon.png" alt="Shape Images">
                                            <span class="vno-box">42</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Single Slide -->
    </div>

    <section id="How-It-Works" class="how-it-works-section">
        <div class="how-it-works-wrap">
            <div class="row no-gutters">
                <div class="col-xs-12 col-sm-6 col-md-5">
                    <div class="how-it-works-left">
                        <h1 class="left-line">How It <span>Works</span></h1>
                        <p class="vpad"></p>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-7">
                    <div class="how-it-works-right">
                      <a style="cursor:pointer;" *ngIf="!IsVideoClicked">  <img src="assets/img/how-it-work.jpg" class="img-responsive" (click)="videoclick()"></a>
                      <iframe *ngIf="IsVideoClicked" width="100%" class="new-frame" src="https://www.youtube.com/embed/1L5NpxbRxXA?autoplay=1&mute=1&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>					 
        </div>					
    </section>

    <div class="axil-portfolio-area ax-section-gap bg-color-lightest bannerbot">
        <div class="container axil-masonary-wrapper">
            <div class="row">
                <h2 class="title vtitle wow">Great deals in these <span class="bg-all"> Categories</span></h2>
                <div class="col-lg-12">
                    <div class="mesonry-list grid-metro3 mt--20">
                        <!-- Start Single Portfolio -->
                        <div class="portfolio portfolio_style--1 axil-control active portfolio-33-33 cat--1 cat--2">
                            <div class="inner move-up wow" (mouseenter)="isEnteredCategoryOne=true" (mouseleave)="isEnteredCategoryOne=false">
                                <div class="thumb wow paralax-image">
                                    <a><img src="assets/img/new.jpg" alt="" class="bod-rad"></a>
                                </div>
                                <div class="port-overlay-info" [ngClass]="{'reduce-margin' : isEnteredCategoryOne}">
                                    <div class="hover-action" *ngIf="isEnteredCategoryOne">
                                        <div class="row">
                                            <div class="col-4 text-center">
                                                <a href="https://hagglr-workspace.slack.com/archives/D01AV7YFUES" target="_blank"><img
                                                        src="assets/img/slack.png" width="40px" class="img-responsive"></a>
                                            </div>
                                            <div class="col-4 text-center">
                                                <a href="https://m.me/hagglrbot" target="_blank"><img src="assets/img/fb.png"
                                                    width="70px" class="img-responsive"></a>
                                            </div>
                                            <div class="col-4 text-center">
                                                <a href="https://wa.me/19252225108" target="_blank"><img src="assets/img/whatsapp.png" width="60px"
                                                        class="img-responsive"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hover-action">
                                        <h4 class="title hover-text">Food and Drink</h4>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- End Single Portfolio -->

                        <!-- Start Single Portfolio -->
                        <div class="portfolio portfolio_style--1 axil-control portfolio-33-33 cat--2">
                            <div class="inner move-up wow" (mouseenter)="isEnteredCategoryTwo=true" (mouseleave)="isEnteredCategoryTwo=false">
                                <div class="thumb wow paralax-image">
                                    <a><img src="assets/img/new-img1.jpg" alt="" class="bod-rad"></a>
                                </div>
                                <div class="port-overlay-info" [ngClass]="{'reduce-margin' : isEnteredCategoryTwo}">
                                    <div class="hover-action" *ngIf="isEnteredCategoryTwo">
                                        <div class="row">
                                            <div class="col-4 text-center">
                                                <a href="https://hagglr-workspace.slack.com/archives/D01AV7YFUES" target="_blank"><img
                                                        src="assets/img/slack.png" width="40px" class="img-responsive"></a>
                                            </div>
                                            <div class="col-4 text-center">
                                                <a href="https://m.me/hagglrbot" target="_blank"><img src="assets/img/fb.png"
                                                    width="70px" class="img-responsive"></a>
                                            </div>
                                            <div class="col-4 text-center">
                                                <a href="https://wa.me/19252225108" target="_blank"><img src="assets/img/whatsapp.png" width="60px"
                                                        class="img-responsive"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hover-action">
                                        <h4 class="title hover-text">Automotive</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Single Portfolio -->

                        <!-- Start Single Portfolio -->
                        <div class="portfolio portfolio_style--1 portfolio-33-33 cat--3">
                            <div class="inner move-up wow" (mouseenter)="isEnteredCategoryThree=true" (mouseleave)="isEnteredCategoryThree=false">
                                <div class="thumb wow paralax-image">
                                    <a><img src="assets/img/new-img5.jpg" alt="" class="bod-rad"></a>
                                </div>
                                <div class="port-overlay-info" [ngClass]="{'reduce-margin' : isEnteredCategoryThree}">
                                    <div class="hover-action" *ngIf="isEnteredCategoryThree">
                                        <div class="row">
                                            <div class="col-4 text-center">
                                                <a href="https://hagglr-workspace.slack.com/archives/D01AV7YFUES" target="_blank"><img
                                                        src="assets/img/slack.png" width="40px" class="img-responsive"></a>
                                            </div>
                                            <div class="col-4 text-center">
                                                <a href="https://m.me/hagglrbot" target="_blank"><img src="assets/img/fb.png"
                                                    width="70px" class="img-responsive"></a>
                                            </div>
                                            <div class="col-4 text-center">
                                                <a href="https://wa.me/19252225108" target="_blank"><img src="assets/img/whatsapp.png" width="60px"
                                                        class="img-responsive"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hover-action">
                                        <h4 class="title hover-text">Beauty & spa</h4>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <!-- End Single Portfolio -->

                        <!-- Start Single Portfolio -->
                        <div class="portfolio portfolio_style--1 portfolio-33-33 cat--2">
                            <div class="inner move-up wow" (mouseenter)="isEnteredCategoryFour=true" (mouseleave)="isEnteredCategoryFour=false">
                                <div class="thumb wow paralax-image">
                                    <a><img src="assets/img/new-img2.jpg" class="bod-rad" alt=""></a>
                                </div>
                                <div class="port-overlay-info" [ngClass]="{'reduce-margin' : isEnteredCategoryFour}">
                                    <div class="hover-action" *ngIf="isEnteredCategoryFour">
                                        <div class="row">
                                            <div class="col-4 text-center">
                                                <a href="https://hagglr-workspace.slack.com/archives/D01AV7YFUES" target="_blank"><img
                                                        src="assets/img/slack.png" width="40px" class="img-responsive"></a>
                                            </div>
                                            <div class="col-4 text-center">
                                                <a href="https://m.me/hagglrbot" target="_blank"><img src="assets/img/fb.png"
                                                    width="70px" class="img-responsive"></a>
                                            </div>
                                            <div class="col-4 text-center">
                                                <a href="https://wa.me/19252225108" target="_blank"><img src="assets/img/whatsapp.png" width="60px"
                                                        class="img-responsive"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hover-action">
                                        <h4 class="title hover-text">Health & fitness</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Single Portfolio -->

                        <!-- Start Single Portfolio -->
                        <div class="portfolio portfolio_style--1 portfolio-33-33 cat--3">
                            <div class="inner move-up wow" (mouseenter)="isEnteredCategoryFive=true" (mouseleave)="isEnteredCategoryFive=false">
                                <div class="thumb wow paralax-image">

                                    <a><img src="assets/img/new-img-3.jpg" alt="" class="bod-rad"></a>

                                </div>
                                <div class="port-overlay-info" [ngClass]="{'reduce-margin' : isEnteredCategoryFive}">
                                    <div class="hover-action" *ngIf="isEnteredCategoryFive">
                                        <div class="row">
                                            <div class="col-4 text-center">
                                                <a href="https://hagglr-workspace.slack.com/archives/D01AV7YFUES" target="_blank"><img
                                                        src="assets/img/slack.png" width="40px" class="img-responsive"></a>
                                            </div>
                                            <div class="col-4 text-center">
                                                <a href="https://m.me/hagglrbot" target="_blank"><img src="assets/img/fb.png"
                                                    width="70px" class="img-responsive"></a>
                                            </div>
                                            <div class="col-4 text-center">
                                                <a href="https://wa.me/19252225108" target="_blank"><img src="assets/img/whatsapp.png" width="60px"
                                                        class="img-responsive"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hover-action">
                                        <h4 class="title hover-text">Things to do</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Single Portfolio -->

                        <!-- Start Single Portfolio -->
                        <!-- <div class="portfolio portfolio_style--1 col-md-3 cat--2 cat--3">
                            <div class="inner move-up wow">
                                <div class="thumb wow paralax-image">
                                    <a href="#"><img src="assets/img/gallery6.jpg" alt=""></a>
                                </div>
                                <div class="port-overlay-info">
                                    <div class="hover-action">
                                        <h4 class="title"><a href="single-case-study.html">Grocery</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- End Single Portfolio -->
                        
                        <!-- Start Single Portfolio -->
                        <div class="portfolio portfolio_style--1 portfolio-33-33 cat--3">
                            <div class="inner move-up wow" (mouseenter)="isEnteredCategorySix=true" (mouseleave)="isEnteredCategorySix=false">
                                <div class="thumb wow paralax-image">

                                    <a><img src="assets/img/new-img4.jpg" alt="" class="bod-rad"></a>

                                </div>
                                <div class="port-overlay-info" [ngClass]="{'reduce-margin' : isEnteredCategorySix}">
                                    <div class="hover-action" *ngIf="isEnteredCategorySix">
                                        <div class="row">
                                            <div class="col-4 text-center">
                                                <a href="https://hagglr-workspace.slack.com/archives/D01AV7YFUES" target="_blank"><img
                                                        src="assets/img/slack.png" width="40px" class="img-responsive"></a>
                                            </div>
                                            <div class="col-4 text-center">
                                                <a href="https://m.me/hagglrbot" target="_blank"><img src="assets/img/fb.png"
                                                    width="70px" class="img-responsive"></a>
                                            </div>
                                            <div class="col-4 text-center">
                                                <a href="https://wa.me/19252225108" target="_blank"><img src="assets/img/whatsapp.png" width="60px"
                                                        class="img-responsive"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hover-action">
										<h4 class="title hover-text">Services</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Single Portfolio -->
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>    

    <section class="product-slider-section">
        <div class="container">
            <h2 class="vtitle">How to find <span>Hagglr Chatbot?</span></h2>
            <div class="product-slider-wrap">
                <div id="product-slider-one" class="carousel slide">
                    <ol class="carousel-indicators">
                        <li data-target="#product-slider-one" data-slide-to="0"></li>
                        <li data-target="#product-slider-one" data-slide-to="1"></li>
                        <li data-target="#product-slider-one" data-slide-to="2" class="active"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item mchat1">
                            <div class="row no-gutters">
                                <div class="col-md-3 vtext1 move-up wow ">
                                    <div class="product-slider-inner">
                                        <h3>Search</h3>
                                        <p>Go to your favorite messaging app Chats section and search 'Hagglr'</p>
                                    </div>
                                </div>
                                <div class="col-md-2 vtext1 vimg1 move-up wow">
                                    <div class=" paralax-image">
                                    <img src="assets/img/chatbot1.png" class="img-responsive vtext1 move-up wow">
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="carousel-item mchat1">
                            <div class="row no-gutters">
                                <div class="col-md-3 vtext1 move-up wow">
                                    <div class="product-slider-inner">
                                        <h3>Search</h3>
                                        <p>Go to your favorite messaging app Chats section and search 'Hagglr'</p>
                                    </div>
                                </div>
                                <div class="col-md-2 vtext1 vimg1 move-up wow">
                                    <div class=" paralax-image">
                                    <img src="assets/img/chatbot1.png" class="img-responsive vtext1 move-up wow">
                                    </div>
                                </div>
                                <div class="col-md-2 vtext2 vimg2 move-up wow">
                                    <div class=" paralax-image">
                                    <img src="assets/img/chatbot2.png" class="img-responsive vtext1 move-up wow">
                                    </div>
                                    <div class="product-slider-inner with-image">
                                        <h3>Select Hagglr</h3>
                                        <p>Select the Hagglr chatbot from the search results</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="carousel-item active">
                            <div class="row no-gutters">
                                <div class="col-md-3 vtext1 move-up wow">
                                    <div class="product-slider-inner">
                                        <h3>Search</h3>
                                        <p>Go to your favorite messaging app Chats section and search 'Hagglr'</p>
                                    </div>
                                </div>
                                <div class="col-md-2 vtext1 vimg1 move-up wow">
                                    <div class=" paralax-image">
                                    <img src="assets/img/chatbot1.png" class="img-responsive vtext1 move-up wow">
                                    </div>
                                </div>
                                <div class="col-md-2 vtext2 vimg2 move-up wow">
                                    <div class=" paralax-image">
                                    <img src="assets/img/chatbot2.png" class="img-responsive vtext1 move-up wow">
                                    </div>
                                    <div class="product-slider-inner with-image">
                                        <h3>Select Hagglr</h3>
                                        <p>Select the Hagglr chatbot from the search results</p>
                                    </div>
                                </div>
                                <div class="col-md-2 vtext3 vimg3 move-up wow">
                                    <div class=" paralax-image">
                                    <img src="assets/img/chatbot3.png" class="img-responsive vtext3 move-up wow">
                                    </div>
                                </div>
                                <div class="col-md-3 vtext3 move-up wow">
                                    <div class="product-slider-inner">
                                        <h3>Get the best deals and save money</h3>
                                        <p>Just say Hi and ask for the deals that you are looking for. e.g. 'Get some Italian restaurant deals'</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>
            </div>
        </div>
    </section>

    <div class="axil-service-area ax-section-gap bg-color-white">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center">
                        <h2 class="title wow" data-splitting>Why <span class="bg-all">Hagglr</span></h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- Start Single Service -->
                <div class="col-lg-4 col-md-6 col-sm-6 col-12 move-up wow">
                    <div class="axil-service text-center axil-control paralax-image">
                        <div class="inner">
                            <img src="assets/img/downloads.jpg" alt="Icon Images">
                            <div class="content">
                                <h4 class="title wow"><a href="single-service.html">No Download</a></h4>
                                <p class="wow">Hagglr is an AI-Powered Chatbot already available in all your favorite messaging apps.Just search for Hagglr in your chat or contacts section and start ask for the best deals.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Single Service -->

                <!-- Start Single Service -->
                <div class="col-lg-4 col-md-6 col-sm-6 col-12 move-up wow">
                    <div class="axil-service text-center axil-control paralax-image active">
                        <div class="inner">
                            <img src="assets/img/no-sign-up.jpg" alt="Shape Images">
                            <div class="content">
                                <h4 class="title wow"><a href="single-service.html">No Sign Up</a></h4>
                                <p class="wow">Hagglr Chatbot is already part of your favorite messaging apps. No registration, No Sign up, No Login required. Just search for Hagglr and get the best deals and save money</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Single Service -->

                <!-- Start Single Service -->
                <div class="col-lg-4 col-md-6 col-sm-6 col-12 move-up wow">
                    <div class="axil-service text-center axil-control paralax-image">
                        <div class="inner">
                            <img src="assets/img/no-purchase.jpg" alt="Shape Images">
                            <div class="content">
                                <h4 class="title wow"><a href="single-service.html">No credit card </a></h4>
                                <p class="wow">Hagglr gets you the real time instant deals. Just ask for the deal and go to the merchant. No credit card required and no need to purchase the deals.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Single Service -->

            </div>
        </div>
    </div>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h4 class="modal-title" style="text-align: center; width: 100%;" id="modal-basic-title">Coming Soon</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </ng-template>
</main>
<public-footer (NavigatePage)="navigatePageTo($event)"></public-footer>
