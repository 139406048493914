<public-header (NavigatePage)="navigatePageTo($event)"></public-header>
<div class="main-content">

    <div id="join-for-free" class="axil-about-us-area grow-bg ax-section-gap axil-shape-position">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12 col-12">
                    <div class="axil-about-inner">
                        <div class="section-title grow-pad text-left benefit">
                            <h2 class="title move-up wow">Grow your <br /> business by</h2>
                            <h1 class="title move-up wow">10x </h1>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 offset-xl-2 col-md-12 col-12 mt_md--30 mt_sm--30">
                    <div class="contact-form-wrapper">
                        <!-- Start Contact Form -->
                        <div class="axil-contact-form contact-form-style-1">
                            <h3 class="title new-title"> Reach millions of customers <br> <span class="bg-all"> for free !</span></h3>
                            <div class="row signup-row">
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 column11">
                                    <a (click)="loginFb()"><img src="assets/img/facebook.png" class="img-fluid"
                                            style="width: 100%"  ></a>
                                </div>
                                <div class="txt-div or">
                                    <p class="or-txt">-or-</p>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12  column11">
                                    <a #loginRef><img src="assets/img/googlesign.png" class="img-fluid"
                                            style="width: 100%" ></a>
                                </div>
                                <div class="txt-div or2">
                                    <p class="or-txt">-OR-</p>
                                </div>
                            </div>
                            <ngb-alert *ngIf="alert.type !=''" [type]="alert.type" (close)="close(alert)">{{ alert.message }}
                            </ngb-alert>
                            <form [formGroup]="registrationForm" (ngSubmit)="registerNewUser()" class="form-horizontal m-t-30"
                            action="#">
                                <div class="form-group position-relative">
                                    <!-- <input type="text"> -->
                                    <input type="email"  name="businessemail" placeholder="Business Email"
                                         id="inputBusinessEmail"
                                        formControlName="inputBusinessEmail"
                                        [ngClass]="{ 'is-invalid': submitted && f.inputBusinessEmail.errors }">
                                    <div *ngIf="submitted && f.inputBusinessEmail.errors" class="invalid-feedback">
                                        <div *ngIf="f.inputBusinessEmail.errors">Please provide a valid business email.
                                        </div>
                                    </div>
                                    <!-- <label>Business Email</label> -->
                                    <span class="focus-border"></span>
                                </div>
                                <div class="form-group position-relative">
                                    <!-- <input type="email"> -->
                                    <input type="text" name="businessname" placeholder="Business Name"
                                         id="inputBusinessName"
                                        formControlName="inputBusinessName"
                                        [ngClass]="{ 'is-invalid': submitted && f.inputBusinessName.errors }">
                                    <div *ngIf="submitted && f.inputBusinessName.errors" class="invalid-feedback">
                                        <div *ngIf="f.inputBusinessName.errors.required">Please provide a valid business
                                            name.
                                        </div>
                                    </div>
                                    <!-- <label>Business Name</label> -->
                                    <span class="focus-border"></span>
                                </div>
                                <div class="form-group position-relative">
                                    <!-- <input type="text"> -->
                                    <input type="text" data-phone-input-format-dir placeholder="Business Phone"
                                        [actualValue]='f.inputBusinessPhone.value'
                                        name="businessphone" id="inputBusinessPhone"
                                        formControlName="inputBusinessPhone"
                                        [ngClass]="{ 'is-invalid': submitted && f.inputBusinessPhone.errors }">
                                    <div *ngIf="submitted && f.inputBusinessPhone.errors" class="invalid-feedback">
                                        <div *ngIf="f.inputBusinessPhone.errors">Please provide a valid phone number.
                                        </div>
                                    </div>
                                    <!-- <label>Business Phone</label> -->
                                    <span class="focus-border"></span>
                                </div>
                                <div class="form-group position-relative">
                                    <!-- <input type="text"> -->
                                    <input type="text" name="businesszipcode" id="inputBusinessZip" placeholder="Business Zip Code"
                                        formControlName="inputBusinessZip"
                                        [ngClass]="{ 'is-invalid': submitted && f.inputBusinessZip.errors }">
                                    <div *ngIf="submitted && f.inputBusinessZip.errors" class="invalid-feedback">
                                        <div *ngIf="f.inputBusinessZip.errors">Please provide a valid zip.
                                        </div>
                                    </div>
                                    <!-- <label>Business Zip Code</label> -->
                                    <span class="focus-border"></span>
                                </div>
                                <div class="form-group position-relative">
                                    <!-- <input type="text"> -->
                                    <input type="password"  id="inputPassword" placeholder="Password"
                                         formControlName="inputPassword"
                                        [ngClass]="{ 'is-invalid': submitted && f.inputPassword.errors }">
                                    <div *ngIf="submitted && f.inputPassword.errors" class="invalid-feedback">
                                        <div *ngIf="f.inputPassword.errors.required">Please provide a valid password.
                                        </div>
                                    </div>
                                    <!-- <label>Password</label> -->
                                    <span class="focus-border"></span>
                                </div>
                                <div class="form-group position-relative vsubmit-btn">
                                    <button class="axil-button btn-large btn-transparent w-100">
                                        <span class="button-text">SUBMIT</span>
                                    </button>
                                </div>
                                
                            </form>

                        </div>
                        <!-- End Contact Form -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="video-id"></div>
    <div class="axil-service-area ax-section-gap bg-color-white">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center">
                        <h2 class="title wow vdo-head" data-splitting>How it <span class="bg-all">Works</span></h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 vdo-col">
                    <iframe width="100%" class="new-frame" src="https://www.youtube.com/embed/ZvqU1fnJwSY?rel=0" title="YouTube video player" frameborder="0"
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
            </div>
            </div>
    <div class="axil-service-area ax-section-gap bg-color-white">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center">
                        <h2 class="title wow" data-splitting>How it helps your <span class="bg-all">Business</span></h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- Start Single Service -->
                <div class="col-lg-4 col-md-6 col-sm-6 col-12 move-up wow">
                    <div class="axil-service text-center axil-control paralax-image">
                        <div class="inner">
                            <img src="assets/img/downloads.jpg" alt="Icon Images">
                            <div class="content">
                                <h4 class="title wow"><a href="single-service.html">Spend Less Make More</a></h4>
                                <p class="wow">Throw your deals and promotions for fraction of the cost. Pay only if get new and repeat customers</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Single Service -->

                <!-- Start Single Service -->
                <div class="col-lg-4 col-md-6 col-sm-6 col-12 move-up wow">
                    <div class="axil-service text-center axil-control paralax-image active">
                        <div class="inner">
                            <img src="assets/img/no-sign-up.jpg" alt="Shape Images">
                            <div class="content">
                                <h4 class="title wow"><a href="single-service.html">Get Repeat Customers</a></h4>
                                <p class="wow">Hagglr attracts new and repeat customers. 80 % of the business comess from the repeat customers</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Single Service -->

                <!-- Start Single Service -->
                <div class="col-lg-4 col-md-6 col-sm-6 col-12 move-up wow">
                    <div class="axil-service text-center axil-control paralax-image">
                        <div class="inner">
                            <img src="assets/img/no-purchase.jpg" alt="Shape Images">
                            <div class="content">
                                <h4 class="title wow"><a href="single-service.html">AI</a></h4>
                                <p class="wow"> Hagglr generates deals automatically and brings customers only when you are slow.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Single Service -->

            </div>
        </div>
    </div>

    <div class="axil-counterup-area ax-section-gap bg-color-white">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center">
                        <h2 class="title wow" data-splitting>Go Where <span class="bg-all">Customers Are</span></h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- Start Counterup Area -->
                <div class="col-md-1"></div>
                <div class="col-md-2">
                    <div class="axil-counterup mt--60 text-center counter-first move-up wow">
                        <h3 class="count no-pers">3.2</h3>
                        <h5>Billion users</h5>
                        <p>3.2 billion users on all the messaging apps</p>
                    </div>
                </div>
                <!-- End Counterup Area -->

                <!-- Start Counterup Area -->
                <div class="col-md-6">
                    <div class="move-up wow vtext4">
                        <img src="assets/img/go-where.png" class="img-responsive">
                    </div>
                    <div class="axil-counterup text-center counter-four move-up wow">
                        <h3 class="count">27%</h3>
                        <h5>of users</h5>
                        <p>spending 24 mins/day on average on messaging apps and the numbers are on the rise</p>
                    </div>
                </div>
                <!-- End Counterup Area -->

                <!-- Start Counterup Area -->
                <div class="col-md-2">
                    <div class="axil-counterup mt--60 mt_md--30 mt_sm--30 text-center counter-third move-up wow">
                        <h3 class="count">55%</h3>
                        <h5>deal searches</h5>
                        <p>will be through voice assistants like Alexa and Google Assistant by 2024</p>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <!-- End Counterup Area -->


            </div>
        </div>
    </div>

    <section class="testimonial-slider-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center">
                        <h2 class="title wow" data-splitting>What they say <span class="bg-all">about us!</span></h2>
                        <p class="subtitle-2 wow words chars splitting animated"> </p>
                    </div>
                </div>
            </div>
            <div class="testimonial-wrap">
                <div class="row no-gutters">
                    <div class="col-xs-12 col-sm-7">
                        <div class="testimonial-left">
                            <img src="assets/img/testimonial-img.jpg" class="img-responsive" />
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-5">
                        <div id="testimonial-slider-one" class="carousel slide" data-ride="carousel" variablewidth="false">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12">
                                            <div class="testimonial-slider-inner">
                                                <p>Hagglr is keeping us busy all the time, we can throw great deals at
                                                    our convenince to attract repeast customers. It's a win win for both
                                                    of us</p>
                                                <div class="testimonialer">
                                                    <img src="assets/img/avat.png" class="img-responsive" />
                                                    <h3>Max Zappata</h3>
                                                    <h4>Quality Auto Services, San Francisco</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12">
                                            <div class="testimonial-slider-inner">
                                                <p>Hagglr is keeping us busy all the time, we can throw great deals at
                                                    our convenince to attract repeast customers. It's a win win for both
                                                    of us</p>
                                                <div class="testimonialer">
                                                    <img src="assets/img/avat.png" class="img-responsive" />
                                                    <h3>Max Zappata</h3>
                                                    <h4>Quality Auto Services, San Francisco</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div id="pricing" class="axil-pricing-table-area pricing-shape-position ax-section-gap bg-color-lightest">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center">
                        <h2 class="title wow" data-splitting>Plans &   <span class="bg-all">Pricing</span></h2>
                        <p class="subtitle-2 wow words chars splitting animated">There are millions of small businesses impacted due to COVID situation. We want to help them to get back on track. We are happy to announce free subscription.</p>
                    </div>
                </div>
            </div>
            <div class="row mt--20 row--30 mt_sm--0">
                <!-- Start Single pricing table -->
                <div class="col-lg-5 col-md-6 col-sm-12 col-12 offset-lg-1">
                    <div class="axil-pricing-table mt--40 active move-up wow">
                        <div class="axil-pricing-inner">
                            <div class="pricing-header">
                                <div class="price-wrapper">
                                    <div class="price">
                                        <h2 class="heading headin-h3">Pro</h2>
                                    </div>
                                    <div class="date-option">
                                        <a class="axil-button btn-large btn-transparent" id="join-for-free">
                                    <span class="button-text">Sign Up for Free</span></a>
                                    </div>
                                </div>
                                <div class="pricing-get-button">
                                    
                                </div>
                            </div>
                            <div class="pricing-body">
                                <div class="inner">
                                    <ul class="list-style">
                                        <li>Post Unlimited Deals</li>
                                        <li>Facebook Messenger</li>
                                        <li>Slack</li>
                                        <li>Alexa</li>
                                        <li>Google Assistant</li>
                                        <li>Viber, Telegram and Whatsapp</li>
                                        <li>Bixby (Samsung Devices)</li>
                                        <li>Integration with DoorDash, UberEats, PostMates and Grubhub</li>
                                        <li>Top of the list</li>
                                        <li>Rewards Points</li>
                                        <li>Post Deals only to the Loyal and Repeat customers</li>
                                        <li>Option to Purchase Deals, Make Reservation, Book Appointments and much more</li>
                                        <li>Auto generated deals using AI</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Single pricing table -->

                <!-- Start Single pricing table -->
                <div class="col-lg-5 col-md-6 col-sm-12 col-12 offset-lg-1">
                    <div class="axil-pricing-table mt--40 active move-up wow">
                        <div class="axil-pricing-inner">
                            <div class="pricing-header">
                                <div class="price-wrapper">
                                    <div class="price">
                                        <h2 class="heading headin-h3">Premium</h2>
                                    </div>
                                    <div class="date-option" style="padding: 0px;height: 52px;">
                                        <a class="axil-button btn-large btn-transparent" href="#join-for-free">
                                    <span class="button-text">Contact Sales</span></a>
                                    </div>
                                </div>
                                <div class="pricing-get-button">
                                    
                                </div>
                            </div>
                            <div class="pricing-body">
                                <div class="inner">
                                    <ul class="list-style">
                                        <li>Post Unlimited Deals</li>
                                        <li>Facebook Messenger</li>
                                        <li>Slack</li>
                                        <li>Alexa</li>
                                        <li>Google Assistant</li>
                                        <li>Viber, Telegram and Whatsapp</li>
                                        <li>Bixby (Samsung Devices)</li>
                                        <li>Integration with DoorDash, UberEats, PostMates and Grubhub</li>
                                        <li>Top of the list</li>
                                        <li>Rewards Points</li>
                                        <li>Post Deals only to the Loyal and Repeat customers</li>
                                        <li>Option to Purchase Deals, Make Reservation, Book Appointments and much more</li>
                                        <li>Auto generated deals using AI</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Single pricing table -->
            </div>
        </div>
        
    </div>
</div>
<contact-form></contact-form>
<public-footer (NavigatePage)="navigatePageTo($event)"></public-footer>