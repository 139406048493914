
    <div class="topbar">
        <div class="topbar-left">
            <a routerLink="/member/dashboard" class="logo">
                <span><img src="assets/img/logo.png" width="220" height="60" alt="logo"></span>
                <i><img src="assets/img/fav-logo.png" width="220" height="60" alt="logo"></i>
            </a>
        </div>
        <nav class="navbar-custom">
            <ul class="navbar-right list-inline float-right mb-0">
                <li class="float-left">
                    <button class="button-menu-mobile open-left waves-effect"><i class="mdi mdi-menu"></i></button>
                </li>
            </ul>
        </nav>
    </div>
    <div class="left side-menu">
        <div class="slimscroll-menu" id="remove-scroll">
            <div id="sidebar-menu">
                <ul class="metismenu" id="side-menu">
                    <li>
                        <a routerLink="/member/dashboard" id="dashboard" class="waves-effect"><i class="ti-home"></i><span>Dashboard</span></a>
                    </li>
                    <!--<li>
                        <a href="profile.html" class="waves-effect"><i class="ti-user"></i><span> Profile</span></a>
                    </li> -->
                    <li>
                        <a routerLink="/member/dealbuilder" id="deal" class="waves-effect"><i class="ti-gift"></i><span> Deal
                                Builder</span></a>
                    </li>
                    <li *ngIf = "isAdmin">
                        <a routerLink="/member/messages" id="message" class="waves-effect" ><i class="ti-comment"></i><span> Messages</span></a>
                    </li>
                    <li>
                        <a routerLink="/member/profile" id="message" class="waves-effect" ><i class="ti-user"></i><span> Profile</span></a>
                    </li>
                    <li>
                        <a (click)="GoHome()"  class="waves-effect"><i class="ti-share-alt"></i><span> Log out</span></a>
                    </li>
                    <!-- <li>
                        <a href="deal-builder.html" class="waves-effect"><i class="ti-thumb-up"></i><span> Deal Builder
                                3</span></a>
                    </li>
                    <li>
                        <a href="deal-builder.html" class="waves-effect"><i class="ti-thumb-up"></i><span> Deal Builder
                                4</span></a>
                    </li> -->
                </ul>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>

    