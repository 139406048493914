import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidator } from 'src/app/shared/helpers/validation.helper';
import { PublicApi } from 'src/app/shared/services/publicApi';
import { UserService } from 'src/app/shared/services/user.service';



@Component({
  selector: 'app-business',
  templateUrl: './../views/business.component.html',
  styleUrls: ['./../styles/business.component.css']
})
export class BusinessComponent implements OnInit {
  @ViewChild("pricing", { static: false }) pricing;
  @ViewChild("contactus", { static: false }) contactus;
  scrollsection: any = '';

  @ViewChild("contact") contactElement: ElementRef;
  public registrationForm: FormGroup;
  submitted: boolean = false;
  apiInProgress: boolean = false;
  alert: any = { type: '', message: '' }
  auth2: any;
  youtubeurl: any = "https://youtu.be/ZvqU1fnJwSY";
  @ViewChild('loginRef') loginElement!: ElementRef;
  constructor(public fb: FormBuilder,
    public router: Router,
    public _api: PublicApi,
    public _userSvc: UserService, public zone: NgZone) { }

  ngOnInit(): void {
    //this.LoadScripts();
    this.registrationForm = this.fb.group({
      inputName: [''],
      inputPassword: ['', Validators.required],
      inputBusinessName: ['', Validators.required],
      inputBusinessEmail: ['', [Validators.required, Validators.email]],
      inputBusinessPhone: ['', [Validators.required, CustomValidator.phoneValidator]],
      inputBusinessZip: ['', [Validators.required, CustomValidator.zipCodeValidator]]
    });
    this.googleSDK();
    this.fbLibrary();
  }

  get f() {
    return this.registrationForm.controls;
  }

  goToLink() {
    window.open(this.youtubeurl, "_blank");
  }

  prepareReqObj() {
    return {
      "businessId": null,
      "businessName": this.registrationForm.value.inputBusinessName,
      "emailAddress": this.registrationForm.value.inputBusinessEmail,
      "password": this.registrationForm.value.inputPassword,
      "firstName": this.registrationForm.value.inputName,
      "lastName": null,
      "phone": this.registrationForm.value.inputBusinessPhone ? this.registrationForm.value.inputBusinessPhone.split('-').join('') : null,
      "zipCode": this.registrationForm.value.inputBusinessZip,
      "latitude": null,
      "longitude": null,
      "businessImage": null,
      "fax": null,
      "category": null,
      "subCategory": null,
      "businessUrl": null,
      "streetAddress": null,
      "city": null,
      "state": null,
      "status": "Active" //TODO: Change this to In Active
    }
  }

  registerNewUser() {
    this.submitted = true;
    if (this.registrationForm.valid) {
      this.apiInProgress = true;
      this._api.register(this.prepareReqObj()).subscribe(
        (res: any) => {
          if (res.businessId) {
            this._userSvc.setUserData(res);
            localStorage.setItem('userData', JSON.stringify(res));
            this.alert.type = 'success';
            this.alert.message = 'Registration Successful!. Please wait...';
            setTimeout(() => {
              this.zone.run(() => this.router.navigateByUrl('/member/updateProfile'));
            }, 500);
          } else {
            this.alert.type = 'danger';
            this.alert.message = res.error;
            this.apiInProgress = false;
          }
        },
        (error: any) => {
          this.alert.type = 'danger';
          this.alert.message = 'Please check the input data';
          this.apiInProgress = false;
        });
    }
  }

  LoadScripts() {
    this.RemoveScripts();

    this.loadLink("assets/css/vendor/bootstrap.min.css");
    this.loadLink("assets/css/plugins/slick.css");
    this.loadLink("assets/css/plugins/font-awesome.css");
    this.loadLink("assets/css/plugins/icomoon.css");
    this.loadLink("assets/css/plugins/plugins.css");
    // this.loadLink("assets/css/style.css");
    this.loadLink("assets/css/style_new.css");

    //this.loadScript('https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js');
    //this.loadScript('https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js');
    this.loadScript('https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js');
    //this.loadScript('assets/js/vendor/bootstrap.min.js');
    this.loadScript('assets/js/waypoints.min.js');
    this.loadScript('assets/js/wow.js');
    this.loadScript('assets/js/counterup.js');
    this.loadScript('assets/js/imagesloaded.js');
    this.loadScript('assets/js/isotope.js');
    this.loadScript('assets/js/tilt.js');
    this.loadScript('assets/js/anime.js');
    this.loadScript('assets/js/tweenmax.js');
    this.loadScript('assets/js/slipting.js');
    this.loadScript('assets/js/scrollmagic.js');
    this.loadScript('assets/js/addindicators.js');
    this.loadScript('assets/js/slick.js');
    this.loadScript('assets/js/youtube.js');
    this.loadScript('assets/js/countdown.js');
    this.loadScript('assets/js/scrollup.js');
    this.loadScript('assets/js/stickysidebar.js');
    this.loadScript('assets/js/contactform.js');
    this.loadScript('assets/js/plugins/plugins.min.js');
    this.loadScript('assets/js/main.js');

    //this.onActivate();
  }

  public RemoveScripts() {
    const body = <HTMLDivElement>document.body;
    var scripts = body.getElementsByTagName('script');
    var i = scripts.length;
    while (i--) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }

    var links = body.getElementsByTagName('link');
    var j = links.length;
    while (j--) {
      links[j].parentNode.removeChild(links[j]);
    }
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  public loadLink(url: string) {
    const body = <HTMLDivElement>document.body;
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    body.appendChild(link);
  }

  public scrollToContact() {
    const contactRefElement = document.querySelectorAll('#contact')[0];
    contactRefElement.scrollIntoView({ behavior: 'smooth' });
  }

  onActivate() {
    return;
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  /**
    * Google Login
    */

  prepareLoginButton() {

    this.auth2.attachClickHandler(this.loginElement.nativeElement, {},
      (googleUser: any) => {

        let profile = googleUser.getBasicProfile();
        // console.log('Token || ' + googleUser.getAuthResponse().id_token);
        // console.log('ID: ' + profile.getId());
        // console.log('Name: ' + profile.getName());
        // console.log('Image URL: ' + profile.getImageUrl());
        // console.log('Email: ' + profile.getEmail());
        //YOUR CODE HERE
        let postdata = {
          "emailAddress": profile.getEmail(),
          "password": "",
          "source": "google",
          "businessName": profile.getName(),
          "city": ""
        }
        this._api.socialMediaLogin(postdata).subscribe((res: any) => {
          if (res.businessId) {
            this._userSvc.setUserData(res);
            localStorage.setItem('userData', JSON.stringify(res));
            this.alert.type = 'success';
            this.alert.message = 'Login Successful!. Please wait...';
            //this.router.navigate(['/member/dashboard']);
            this.zone.run(() => this.router.navigateByUrl('/member/dashboard'));
          } else {
            this.alert.type = 'danger';
            this.alert.message = res.error;
          }
        }, () => { })

      }, (error: any) => {
        //alert(JSON.stringify(error, undefined, 2));
      });

  }

  googleSDK() {

    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        this.auth2 = window['gapi'].auth2.init({
          client_id: '528597511411-3trmul0klftl806it793fhb1644q2m7b.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
          scope: 'profile email'
        });
        this.prepareLoginButton();
      });
    }

    (function (d, s, id) {
      var js: any, fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-jssdk'));

  }
  /**
   * FB Login
   */
  loginFb() {
    window['FB'].login((response: { authResponse: any; }) => {
      // console.log('login response', response);
      if (response.authResponse) {

        window['FB'].api('/me', {
          fields: 'last_name, first_name, email'
        }, (userInfo: { email: any; first_name: string; last_name: string; }) => {

          //  console.log("user information");
          // console.log(userInfo);
          let postdata = {
            "emailAddress": userInfo.email,
            "password": "",
            "source": "facebook",
            "businessName": userInfo.first_name + ' ' + userInfo.last_name,
            "city": ""
          }
          this._api.socialMediaLogin(postdata).subscribe((res: any) => {
            if (res.businessId) {
              this._userSvc.setUserData(res);
              localStorage.setItem('userData', JSON.stringify(res));
              this.alert.type = 'success';
              this.alert.message = 'Login Successful!. Please wait...';
              this.zone.run(() => this.router.navigateByUrl('/member/dashboard'));
              //this.router.navigate(['/member/dashboard']);
            } else {
              this.alert.type = 'danger';
              this.alert.message = res.error;
            }
          }, () => { })
        });

      } else {
        //  console.log('User login failed');
      }
    }, { scope: 'email' });
  }

  fbLibrary() {

    (window as any).fbAsyncInit = function () {
      window['FB'].init({
        appId: '3819439784750389',
        cookie: true,
        xfbml: true,
        version: 'v9.0'
        //version: 'v3.1'
      });
      window['FB'].AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js: any, fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  navigatePageTo(element: string) {
    //alert('business - ' + element);
    var str_split = element.split(',');
    if (str_split[0] == 'business') {
      //alert(str_split[1] + 'navigatePageTo');
      //document.querySelector(str_split[1]).scrollIntoView({ behavior: "smooth", block: "start" });
      this.router.navigate(['/public/business'])
        .then(() => {
          setTimeout(() => {
            document.querySelector(str_split[1]).scrollIntoView({ behavior: "auto" });
          }, 2000);
        });
    }
    else if (str_split[0] == 'home') {      
      this.router.navigate(['/public'])
        .then(() => {
          setTimeout(() => {
            document.querySelector(str_split[1]).scrollIntoView({ behavior: "auto"});
          }, 1000);
        });
    }
    else {
    }
  }
}
