<div id="wrapper">
    <member-header></member-header>
    <div class="bg-light">
        <div class="content-page">
            <div class="content">
                <div class="container-fluid">
                    <div class="page-title-box">
                        <div class="row align-items-center">
                            <div class="col-sm-6">
                                <h4 class="page-title">Deal Builder</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li class="breadcrumb-item active">Deal Builder</li>
                                </ol>
                            </div>
                            <div class="col-sm-6">
                                <div class="float-right d-md-block">
                                    <a class="btn btn-primary add-btn add-btnarrow-none waves-effect waves-light"
                                        data-toggle="tooltip" data-placement="bottom" title=""
                                        data-original-title="Add Deal Builder" (click)="gotoNewDeal()"><i
                                            class="fa fa-plus mr-2"></i> Add</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <ngb-alert *ngIf="alert.type !=''" [type]="alert.type" (close)="alert.type =''">
                                {{ alert.message }}
                            </ngb-alert>
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="mt-0 header-title">Deal Builders List</h4>
                                    <ngb-alert *ngIf="deals.length == 0" type="warning">No deals found</ngb-alert>
                                    <table id="datatable-buttons" *ngIf="deals.length > 0"
                                        class="table table-striped table-bordered dt-responsive nowrap"
                                        style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                        <thead>
                                            <tr class="vdeal-builder">
                                                <th class="cursor-pointer" (click)="sortData('name')">Deal Name <i
                                                        class="fas ml-2"
                                                        [ngClass]="(sortBy ==='name' ? sortDirClass : 'fa-sort')"></i>
                                                </th>
                                                <th class="cursor-pointer" (click)="sortData('dealType')">Deal type <i
                                                        class="fas ml-2"
                                                        [ngClass]="(sortBy ==='dealType' ? sortDirClass : 'fa-sort')"></i>
                                                </th>
                                                <th class="cursor-pointer" (click)="sortData('todate')">Expiration Date
                                                    <i class="fas ml-2"
                                                        [ngClass]="(sortBy ==='todate' ? sortDirClass : 'fa-sort')"></i>
                                                </th>
                                                <th class="cursor-pointer" (click)="sortData('dealstatus')">Deal Status
                                                    <i class="fas ml-2"
                                                        [ngClass]="(sortBy ==='dealstatus' ? sortDirClass : 'fa-sort')"></i>
                                                </th>
                                                <th class="cursor-pointer" (click)="sortData('couponlimit')">Total Deals
                                                    <i class="fas ml-2"
                                                        [ngClass]="(sortBy ==='couponlimit' ? sortDirClass : 'fa-sort')"></i>
                                                </th>
                                                <th class="cursor-pointer" (click)="sortData('couponsRedeemed')">Total
                                                    Deals used <i class="fas ml-2"
                                                        [ngClass]="(sortBy ==='couponsRedeemed' ? sortDirClass : 'fa-sort')"></i>
                                                </th>
                                                <th class="cursor-pointer" (click)="sortData('couponsAvailable')">Total
                                                    Available Deals <i class="fas ml-2"
                                                        [ngClass]="(sortBy ==='couponsAvailable' ? sortDirClass : 'fa-sort')"></i>
                                                </th>
                                                <th width="10%">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let deal of deals;let i =index">
                                                <td>{{deal?.name}}</td>
                                                <td>{{deal?.dealType}}</td>
                                                <td>{{deal?.todate | date: 'shortDate'}}</td>
                                                <td>
                                                    <label class="badge badge-info">{{deal.dealstatus}}</label>
                                                    <!-- <div class="custom-control custom-switch text-center">
                                                        <input type="checkbox" class="custom-control-input pointer"
                                                            (click)="toggleStatus(deal?.id,deal?.dealstatus,i)"
                                                            [id]="'customSwitch'+i"
                                                            [checked]="deal?.dealstatus === 'Active'">
                                                        <label class="custom-control-label"
                                                            [for]="'customSwitch'+i"></label>
                                                    </div> -->
                                                </td>
                                                <td>{{deal?.couponlimit}}</td>
                                                <td>{{deal?.couponsRedeemed}}</td>
                                                <td>{{deal?.couponsAvailable}}</td>

                                                <td>
                                                    <a data-toggle="tooltip" data-placement="top" title=""
                                                        data-original-title="View"
                                                        class="btn btn-primary table-btn waves-effect waves-light"
                                                        role="button" href="javascript:;"
                                                        (click)="viewDeal(deal?.id);" style="margin: 3px;"><i class="ti-eye"></i></a>
                                                    <a data-toggle="tooltip" data-placement="top" title=""
                                                        data-original-title="Edit"
                                                        class="btn btn-success table-btn waves-effect waves-light"
                                                        role="button" href="javascript:;"
                                                        (click)="editThisDeal(deal?.id)" style="margin: 3px;"><i class="ti-pencil"></i></a>
                                                    <a data-toggle="tooltip" data-placement="top" title=""
                                                        data-original-title="Delete"
                                                        class="btn btn-dark table-btn waves-effect waves-light"
                                                        role="button" href="javascript:;"
                                                        (click)="deleteDeal(deal?.id)" style="margin: 3px;"><i class="ti-trash"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="pt-2 pb-2 d-flex" *ngIf="deals.length > 0">
                                        <div class="pl-0">
                                            {{ getPaginationWriteup(paginationData.rowLimit, paginationData.totalRows)
                                            }}
                                        </div>
                                        <div class="pl-0 ml-auto pr-3 mr-2">
                                            <pagination [totalResultCount]="paginationData.totalRows"
                                                [page-size]="paginationData.rowLimit"
                                                (page-changed)="onPageChange($event)"></pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                </div>
            </div>
            <footer class="footer">
                <p>&copy; 2018-2021 Hagglr, Inc.</p>
            </footer>
        </div>
    </div>
    <member-footer></member-footer>
</div>