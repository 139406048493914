
<div id="wrapper">
   <member-header></member-header>
    <div class="content-page">
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h4 class="page-title">Dashboard</h4>
                        </div>
                    </div>
                </div>
                <!-- end row -->
                <div class="row">
                    <div class="col-xl-3 col-md-6">
                        <div class="card mini-stat bg-primary text-white">
                            <div class="card-body">
                                <div class="mb-4 dash-icn">
                                    <div class="float-left mini-stat-img mr-4"><i class="ti-user"></i></div>
                                    <h5 class="font-16 text-uppercase mt-0 text-white-50">Deals</h5>
                                    <h4 class="font-500">1,685 <i class="mdi mdi-arrow-up text-success ml-2"></i></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="card mini-stat bg-primary text-white">
                            <div class="card-body">
                                <div class="mb-4 dash-icn">
                                    <div class="float-left mini-stat-img mr-4"><i class="ti-stats-up"></i></div>
                                    <h5 class="font-16 text-uppercase mt-0 text-white-50">Deals</h5>
                                    <h4 class="font-500">52,368 <i class="mdi mdi-arrow-down text-danger ml-2"></i></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="card mini-stat bg-primary text-white">
                            <div class="card-body">
                                <div class="mb-4 dash-icn">
                                    <div class="float-left mini-stat-img mr-4"><i class="ti-gift"></i></div>
                                    <h5 class="font-16 text-uppercase mt-0 text-white-50">Deals</h5>
                                    <h4 class="font-500">15.8 <i class="mdi mdi-arrow-up text-success ml-2"></i></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="card mini-stat bg-primary text-white">
                            <div class="card-body">
                                <div class="mb-4 dash-icn">
                                    <div class="float-left mini-stat-img mr-4"><i class="ti-comments-smiley"></i></div>
                                    <h5 class="font-16 text-uppercase mt-0 text-white-50">Deals</h5>
                                    <h4 class="font-500">2436 <i class="mdi mdi-arrow-up text-success ml-2"></i></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="mt-0 header-title">Deals</h4>
                                <line-chart></line-chart>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="mt-0 header-title">Deals</h4>
                                <bar-chart></bar-chart>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- container-fluid -->
        </div>
        <!-- content -->
    </div>
    
</div>