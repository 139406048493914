import { Component } from "@angular/core";
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { Store, select } from '@ngrx/store';
import * as userState from '../reducers/user.reducer';

@Component({
    selector: 'member-header',
    templateUrl: './../views/header.component.html',
    styleUrls: ['./../styles/memberheader.component.css']
})

export class HeaderComponent {
    userProfile: any;
    activePage: any;
    isAdmin!: boolean;
    //Subscription Object
    userDetails: any;
    selectedMenu: any = "";

    constructor(public router: Router,
        private store: Store<userState.State>,
        public _userSvc: UserService) {
    }

    ngOnInit() {
        this.LoadScripts();
        this.userDetails = this.store.pipe(select(userState.getUserDetails)).subscribe(
            (result) => {
                this.userProfile = result;
               //console.log(result);
            }
        );
     
        let urlStr = this.router.url;
        let urlStrArr = urlStr.split('/');
        this.activePage = urlStrArr[urlStrArr.length - 1];

        let adminUser = ['support@hagglr.ai'];
        let userEmail = this.userProfile.emailAddress.toLowerCase();
        const checkUser = (user: any) => user === userEmail;
        this.isAdmin = adminUser.some(checkUser);
    }

    select(item) {
        this.selectedMenu = item;
    };
    isActive(item) {
        return this.selectedMenu === item;
    };
    LoadScripts() {
        this.RemoveScripts();
        this.loadLink("assets/plugins/chartist/css/chartist.min.css");
        this.loadLink("assets/css/bootstrap.min.css");
        this.loadLink("assets/css/metismenu.min.css");
        this.loadLink("assets/css/icons.css");
        this.loadLink("assets/css/style.css");


        this.loadScript('assets/js/jquery.min.js');
        this.loadScript('assets/js/bootstrap.bundle.min.js');
        this.loadScript('assets/js/metisMenu.min.js');
        this.loadScript('assets/js/jquery.slimscroll.js');
        this.loadScript('assets/js/waves.min.js');
        this.loadScript('assets/plugins/chart.js/chart.min.js');

        this.loadScript('assets/plugins/chartist/js/chartist.min.js');
        this.loadScript('assets/plugins/chartist/js/chartist-plugin-tooltip.min.js');
        this.loadScript('assets/pages/chartist.init.js');
        this.loadScript('assets/pages/chartjs.init.js');
        this.loadScript('assets/js/app.js');
    }

    public RemoveScripts() {
        const body = <HTMLDivElement>document.body;
        var scripts = body.getElementsByTagName('script');
        var i = scripts.length;
        while (i--) {
            scripts[i].parentNode.removeChild(scripts[i]);
        }

        var links = body.getElementsByTagName('link');
        var j = links.length;
        while (j--) {
            links[j].parentNode.removeChild(links[j]);
        }
    }
    public loadScript(url: string) {

        const body = <HTMLDivElement>document.body;
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = true;
        body.appendChild(script);
    }

    public loadLink(url: string) {
        const body = <HTMLDivElement>document.body;
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = url;
        body.appendChild(link);
    }

    logout() {
        if (this._userSvc.logout()) {
            this.router.navigate(['public/login']);
        }
    }

    GoHome() {
        this.router.navigate(['/public'])
            .then(() => {
                window.location.reload();
            });
    }

}