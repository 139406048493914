<public-header (NavigatePage)="navigatePageTo($event)"></public-header>
<section id="terms" class="top-section">
    <div class="container">
        <div class="section-header text-center">
            <h2 class="section-title wow fadeInDown" data-wow-delay="0.3s">Privacy Policy</h2>
            <div class="shape wow fadeInDown" data-wow-delay="0.3s"></div>
        </div>
        <div class="text">
            <h3>Hagglr, Inc. Privacy Statement</h3>
            <span>Effective Date : May 2018</span> 

            <p class="termsp mt-4">This Privacy Statement (“Privacy Statement”) explains how Hagglr, Inc. (“Hagglr,”
                “us,” “our,” and “we”) uses your information and applies to all who use our Web site – www.Hagglr.ai, as
                well as all information provided via SMS, Facebook Messenger, WhatsApp, Slack, Alexa, Google Home, and
                other text based and voice based communications services are collectively known as the “Medium”. The
                terms "we", "us", "our" and "Hagglr" refer to Hagglr Inc., a Delaware incorporated company. The term
                "you" refers to the customer visiting the Medium.</p>

            <p class="termsp">Hagglr knows that you care how information about you is used and shared, and we appreciate
                your trust that we will do so carefully and sensibly. This notice describes our privacy policy.
                <strong>By visiting our ‘Medium’, you are accepting the practices described in this Privacy
                    Notice.</strong></p>

            <p class="termsp">In addition, please review the Site’s Terms of services which governs your use of the
                Service, and includes, among other things, grants of rights from you, limits on our liability and your
                remedies, mandatory arbitration, and waiver of jury trial and class actions. <strong>By visiting our
                    ‘Medium’, you agree to our Terms of Service and consent to our collection, use and sharing of your
                    information and data, and other activities, as described below.</strong></p>

            <p class="termsp">We may modify this Privacy Statement at any time. If we make any material changes, we will
                notify you by email (sent to the email address specified in your account) or by means of notice on the
                Site prior to the change becoming effective. You can determine when this Privacy Statement was last
                revised by referring to the "Effective Date” above. </p>

            <p class="termsp">By using the ‘Medium’, you agree to the terms of this Privacy Statement. If you do not
                agree with the practices described in this Privacy Statement, please do not provide us with your
                personal information or interact with the Site or ‘Medium’
            </p>
        </div>

        <div class="text mt-4">
            <h4>Information We Collect</h4>
            <p class="termsp">We will collect information, including Personal Information and non identifying
                information, when you interact with us through the medium, for example when you: </p>
            <ul>
                <li>access or use the Site/Medium;</li>
                <li>register, subscribe, or create an account with Hagglr;</li>
                <li>open or respond to our e-mails;</li>
                <li>refer friends, family, or others to Hagglr;</li>
                <li>contact customer service or use other customer support tools;</li>
                <li>visit any page online that displays our ads or content;</li>
                <li>provide information to enroll or participate in programs provided on behalf of, or together with,
                    merchants/business partners;</li>
                <li>purchase products or services on or through the Site;</li>
                <li>connect or link to any Site via social networking sites;</li>
                <li>post comments to online communities; and</li>
                <li>provide information to our business partners/merchants</li>
                <li>This Privacy Statement does not apply to the collection of information in any way other than as
                    listed above.</li>
            </ul>
        </div>

        <div class="text mt-4">
            <h4>How Hagglr Collects Information</h4>

            <p class="termsp">You provide us with your Personal Information when you register, subscribe, create an
                account, make a purchase, or otherwise when you provide us with your Personal Information during your
                interaction with the Site and Other programs. We also collect Personal Information when you contact us
                online for customer service and other support using self-help tools, such as email, text, or by posting
                to an Online community</p>
            <p class="termsp">We also receive Personal Information and other online and offline information from other
                sources Hagglr will use such information in accordance with applicable laws. Such information, when
                combined with Personal Information collected as provided in this Privacy Statement, will also be handled
                in accordance with this Privacy Statement. We also use cookies, tags, web beacons, local shared objects,
                files, tools and programs to keep records, store your preferences, improve our advertising, and collect
                non-identifying information, including Device Data and information about your interaction with the
                Site/Medium and our Business partners/merchants web sites.</p>
            <p class="termsp">We use Cookies and Device Data that allow us to connect your Site/Medium activity with
                other information we store about you in your profile or as related to your interactions with the Site.
                Device Data may be collected automatically, and as soon as your device interacts with the Site and
                Hagglr, even if you are not logged into the Site/Medium using your device. If you have questions about
                the security and privacy settings of your mobile device, please refer to instructions from your mobile
                service provider or the manufacturer of your device to learn how to adjust your settings.</p>
            <p class="termsp">We collect your location information, including precise real-time geolocation, such as
                through look up based on IP address, GPS, Bluetooth, or WiFi signals, consistent with your choice of
                mobile device settings. </p>
        </div>
        <div class="text mt-4">
            <h4>How Hagglr Uses Information</h4>
            <p class="termsp">We (or our business partners/merchants/vendors our behalf), use information collected as
                described in this Privacy Statement to:</p>
            <ul>
                <li>Operate, maintain and improve the Site/Medium and our services, and other programs;</li>
                <li>Provide you with interest-based ads, push notifications, communications, and offers for products and
                    services from us and participating business partners, including based on your precise geolocation;
                </li>
                <li>Facilitate and fulfill orders placed on the Site/Medium and other programs– for example, for Hagglr
                    vouchers and other goods and services, including tracking redemption;</li>
                <li>Evaluate your eligibility for certain types of offers, products or services that may be of interest
                    to you, and analyze advertising effectiveness;</li>
                <li>Answer your questions and respond to your requests;</li>
                <li>Perform analytics and conduct customer research;</li>
                <li>Communicate and provide additional information that may be of interest to you about Hagglr and our
                    business partners, sometimes by combining your information with information from other sources;</li>
                <li>Send you reminders, technical notices, updates, security alerts, support and administrative
                    messages, service bulletins, marketing messages, and requested information, including on behalf of
                    business partners;</li>
                <li>Administer rewards, surveys, sweepstakes, contests, or other promotional activities or events
                    sponsored by us or our business partners;</li>
                <li>Manage our everyday business needs, such as administration of the Site/Medium, forum management,
                    fulfillment, analytics, fraud prevention, and enforcement of our corporate reporting obligations and
                    terms of use, or to comply with the law;</li>
                <li>Allow you to apply for a job, post a video or sign up for special offers from merchants, business
                    partners, or third parties through the Site/Medium and other programs;</li>
                <li>Enhance other information we have about you directly or from other sources to help us better
                    understand you and determine your interests, and to</li>
            </ul>
            <p class="termsp">We also may use information collected as described in this Privacy Statement with your
                consent or as otherwise required or permitted by law.</p>
            <p class="termsp">Hagglr uses your geolocation, including your precise geolocation, consistent with this
                Privacy Statement as described below to deliver location-based offers, products or services that may be
                of interest to you.</p>

            <p class="termsp">If you use any features on the Site/Medium to send information about a product or service
                to another person (e.g., a friend, a colleague, a professional associate, etc.), we will also collect
                the Personal information of that other person to the extent disclosed by you and may contact them using
                the information you provided us.</p>
        </div>

        <div class="text mt-4">
            <h4>Managing Personal Information</h4>
            <p class="termsp">You can access, update and delete your Personal Information you provided to us, as
                described below, by managing this information through your online account or sending us an email.
                Keeping your Personal Information current helps ensure that we, our Affiliates and Business Partners
                offer you the offers that are most relevant to you.</p>
            <p class="termsp">If you want to deactivate your Hagglr account or have other questions or requests, please
                contact us. While we are ready to assist you in managing your subscriptions, deactivating your account,
                and removing your active profile, we cannot always delete records of past interactions and transactions.
                For example, we are required to retain records relating to previous purchases on the Site for financial
                reporting and compliance reasons.We will retain your Personal Information for as long as your account is
                active or as needed to provide you services and to maintain a record of your transactions for financial
                reporting purposes. We will retain and use your Personal Information as necessary to comply with our
                legal obligations, resolve disputes, and enforce our agreements.</p>
        </div>

        <div class="text mt-4">
            <h4>Social Networks</h4>
            <p class="termsp">You may log in to the Site using certain social network credentials. To manage the sharing
                of certain Personal Information with us when you connect with us through social networking platforms or
                applications, refer to the privacy policy and settings of the social networking website or application.
            </p>
            <p class="termsp">You also have the option to link social networks, such as Facebook, to your Hagglr
                account. Once you register with Hagglr and connect with the social network, you will be able to
                automatically post recent Hagglr activity back to your social network.</p>
            <p class="termsp">By proceeding through any of the above steps, you grant Hagglr permission to access all of
                the elements of your social network profile information that you have made available to be shared and to
                use it in accordance with the social network’s terms of use and this Privacy Statement.</p>
            <ul>
                <li>Violation of our terms and conditions</li>
                <li>Direct violation of the law</li>
                <li>Use of our services</li>
            </ul>
        </div>
        <div class="text mt-4">
            <h4>Privacy Policy of Third Parties</h4>
            <p class="termsp">This Privacy Statement only addresses the use and disclosure of information by Hagglr
                through your interaction with the Site/Medium. Other websites that may be accessible through links from
                the Site and other programs may have their own privacy statements and personal information collection,
                use, and disclosure practices. Our Business Partners may also have their own privacy statements. We
                encourage you to familiarize yourself with the privacy statements provided by these other parties prior
                to providing them with information or taking advantage of a sponsored offer or promotion.</p>
        </div>
        <div class="text mt-4">
            <h4>Notice to Residents of Countries outside the United States of America </h4>
            <p class="termsp">
                If you live outside the United States , and you use the Site or provide us with Personal Information
                directly via the Site, your information will be handled in accordance with this Privacy Statement. By
                using the Site or giving us your Personal Information, you are directly transferring your Personal
                Information and Non-identifiable Information to us in the United States of America. You agree and
                consent to our collection, transfer, and processing of your Personal Information and Non-identifiable
                Information in accordance with this Privacy Statement. You are solely responsible for compliance with
                any data protection or privacy obligations in your jurisdiction when you use the Site or provide us with
                Personal Information Regardless of where we transfer your information, we still protect your information
                in the manner described in this Privacy Statement.</p>
        </div>
        <div class="text mt-4">
            <h4>Children’s Privacy</h4>
            <p class="termsp">By using Hagglr, you represent that you are at least eighteen years old and understand
                that you must be at least eighteen years old in order to create an account and purchase the goods or
                services advertised through the Site. We will promptly delete information associated with any account we
                obtain actual knowledge is associated with a registered user who is not at least eighteen years old.</p>
            <p class="termsp">Please contact us if you have any questions or comments about our privacy practices or
                this Privacy Statement. You can reach us online at <a href="mailto:support@hagglr.ai">support@hagglr.ai</a>. </p>
        </div>
    </div>
</section>
<public-footer (NavigatePage)="navigatePageTo($event)"></public-footer>