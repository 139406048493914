<footer>
  <div class="footer-links">
    <a routerLink="/public/terms">Terms & Conditions</a>
    <a routerLink="/public/privacypolicy">Privacy Policy</a>
    <a href="javascript:void(0)"   (click)="DoMenuNavigate('pricing')">Pricing</a>
    <a href="javascript:void(0)">Blog</a>
    <a  href="javascript:void(0)"  (click)="DoMenuNavigate('contactus')">Contact us</a>
  </div>
  <div class="footer-contact-links">
    <a href="tel:+1 925-237-1069">+1 925-237-1069</a>/<a href="mailto:support@hagglr.ai">support@hagglr.ai</a>
  </div>
  <div class="footer-social-links">
    <a href="https://www.facebook.com/hagglrbot" target="_BLANK"><img src="assets/img/fb-logo.png"></a>
    <a href="https://twitter.com/hagglr_ai" target="_BLANK"><img src="assets/img/twitter-logo.png"></a>
    <a href="https://www.instagram.com/hagglr_ai" target="_BLANK"><img src="assets/img/insta-logo.png"></a>
    <a href="https://www.pinterest.com/HagglrAI" target="_BLANK"><img src="assets/img/pinterest-icon.png"></a> 
    <a href="https://www.linkedin.com/company/hagglr-ai" target="_BLANK"><img src="assets/img/linkedin-logo.png "></a> 
    <a href="https://www.reddit.com/user/hagglr" target="_BLANK"><img src="assets/img/reddit-icon.png"></a> 
    <a href="https://www.tumblr.com/blog/hagglrai" target="_BLANK"><img src="assets/img/tumblr-icon.png"></a> 
  </div>
  <div class="footer-copyrights">
    <p>&copy; 2018-2021 Hagglr, Inc.</p>
  </div>
</footer>