<public-header (NavigatePage)="navigatePageTo($event)"></public-header>
<section id="terms" class="top-section">
    <div class="container">
        <div class="section-header text-center">
            <h2 class="section-title wow fadeInDown" data-wow-delay="0.3s">Terms & Conditions</h2>
            <div class="shape wow fadeInDown" data-wow-delay="0.3s"></div>
        </div>
        <div class="text">
            <h4 class="primary">Effective as of: 1<sup>st</sup>, June 2018</h4>
            <p class="termsp">Thank you for using Hagglr. This Website as well as all information provided via SMS,
                Facebook, Messenger,WhatsApp, Slack, Alexa, Google Home, and other text based and voice based
                communications services are collectively known as the “Medium”. The terms "we", "us", "our" and "Hagglr"
                refer to Hagglr Inc., a Delaware incorporated company. The term "you" refers to the customer visiting
                the Medium.</p>
            <p class="termsp">By accessing or using our services, you agree to be bound by the Agreement. To the extent
                you advertise, or buy any products or services on this Medium through our platform, you agree that this
                Agreement shall apply to all such transactions. Please read the Agreement carefully. Do not access or
                use the Site/Medium if you are unwilling or unable to be bound by the Terms. </p>
        </div>

        <div class="text mt-4">
            <h4>Use of the Site/ Medium</h4>
            <ul>
                <li>You have reached the age of majority in the state or province in which you reside; </li>
                <li>You are able to create a binding legal obligation;</li>
                <li>You are not barred from receiving products or services under applicable law;</li>
                <li>You will not attempt to use the Site/Medium with crawlers, robots, data mining, or extraction tools
                    or any other functionality;</li>
                <li>Your use of the Site/Medium will at all times comply with these Terms of Use;</li>
                <li>You are the authorized person to post the deals in our site/Medium</li>
                <li>You will only make legitimate purchases that comply with the letter and spirit of the terms of the
                    respective offers;</li>
                <li>You will only make purchases on the Site/Medium for your own use and enjoyment or as a gift for
                    another person;</li>
                <li>You have the right to provide any and all information you submit to the Site/Medium, and all such
                    information is accurate, true, current, and complete;</li>
                <li>You must safeguard your password and supervise the use of your account, and understand and agree
                    that you are responsible for the use of your account by anyone you allow to access it.</li>
                <li>You will update and correct information you have submitted to the Site/Medium and ensure that it is
                    accurate at all times</li>
            </ul>
        </div>

        <div class="text mt-4">
            <h4>Disclaimer</h4>

            <p class="termsp">All content, services, and deals provided to you through our platform are provided on an
                “as is” basis and based on the availability at the time of claiming the deal. Hagglr cannot warrant
                anything of any kind, express or implied.</p>
            <p class="termsp">
                To the maximum extent permitted by applicable law and in addition to all other limitations and
                disclaimers in these
                terms and conditions, Hagglr disclaims any liability or loss in connection with the products sold and
                services
                provided. Users are to use our services at their own risk and discretion.</p>
        </div>
        <div class="text mt-4">
            <h4>Merchant Rules and Restrictions</h4>
            <p class="termsp">Hagglr works with several Merchants/Businesses . When a deal is purchased with a
                particular merchant, all of the merchants terms and conditions will also be in effect towards your
                purchase.</p>
            <p class="termsp">Merchants/Businesses are solely responsible for the deals that are posting in Hagglr
                site/Medium.</p>
        </div>

        <div class="text mt-4">
            <h4>Changes or Cancellations</h4>
            <p class="termsp">You can change or cancel your purchase by chatting with Hagglr through one of the
                available mediums.
                However,
                change/cancellation fees may apply in accordance with the purchase conditions. You agree to pay any
                required
                cancellation or change fees that you may incur.
                In the event that a merchant is unable to honour your deal, Hagglr will not be liable for any cost
                incurred. </p>
        </div>

        <div class="text mt-4">
            <h4>Indemnification</h4>
            <p class="termsp">You shall indemnify and hold harmless Hagglr affiliates or their respective suppliers and
                any of our managers,
                employees, advisors, investors, and agents from and against any claims, causes of action, losses,
                damages, fines,
                penalties or other costs or expenses of any nature including but not limited to reasonable legal and
                accounting
                fees, brought by third parties as a result of the following:</p>
            <ul>
                <li>Violation of our terms and conditions</li>
                <li>Direct violation of the law</li>
                <li>Use of our services</li>
            </ul>
        </div>
        <div class="text mt-4">
            <h4>Limitation of Liability</h4>
            <p class="termsp">
                The deals, content, pricing information, and services provided by this Medium may include inaccuracies
                or
                errors. We
                do not guarantee the accuracy of any information presented to you. </p>

            <p class="termsp">
                All information regarding deals provided to you are intended as only general guidelines, and we cannot
                be
                held
                accountable for any discrepancies found in our information. Under no circumstances will Hagglr be liable
                for
                direct,
                indirect, consequential, special or exemplary damages arising from breach of this agreement. </p>
        </div>
        <div class="text mt-4">
            <h4>Websites/ Medium of Others</h4>
            <p class="termsp">
                The Site contains links to webSites/Medium maintained by other parties. These links are provided solely
                as a
                convenience to you and not because we endorse or have an opinion about the contents on such
                webSites/Medium.
                We
                expressly disclaim any representations regarding the content or accuracy of materials on such
                webSites/Medium or
                the
                privacy practices of those webSites/Medium. If you decide to access webSites/Medium maintained by other
                parties,
                you
                do so at your own risk. We are not responsible or liable, directly or indirectly, for any damage, loss,
                or
                liability
                caused or alleged to be caused by or in connection with any use of or reliance on any content, Products,
                or
                services
                available on or through any such linked site or resource.</p>
        </div>
        <div class="text mt-4">
            <h4>Electronic Communications</h4>
            <p class="termsp">
                When you use the Site or send emails to Hagglr, you are communicating with us electronically and consent
                to
                receive
                electronic communications related to your use of the Site. We will communicate with you by email or by
                posting
                notices on the Site. You agree that all agreements, notices, disclosures, and other communications that
                are
                provided
                to you electronically satisfy any legal requirement that such communications be in writing. Notices from
                us
                will
                be
                considered delivered to you and effective when sent to the email address you provide on the Site or from
                which
                you
                otherwise email us. </p>
        </div>
        <br/>
        <br/>
        <div class="text mt-4">
            <p class="termsp"><strong>PLEASE DO NOT ENGAGE IN ANY OF THE FOLLOWING ACTIVITIES – </strong>they are
                prohibited on the Hagglr Sites/Medium and
                ‘Medium’ and constitute express violations of these Terms of Use:</p>
            <ul>
                <li>Submitting any purposely inaccurate information, committing fraud or falsifying information;</li>
                <li>Using the Hagglr Sites/Medium or any of its contents to advertise or solicit, for any other
                    commercial,
                    political or
                    religious purpose, or to compete, directly or indirectly with Hagglr, without Hagglr prior written
                    consent;</li>
                <li>Attempting to, or actually accessing data not intended for you, such as logging into a server or an
                    account
                    which
                    you are not authorized to access;</li>
                <li>Tampering or interfering with the proper functioning of any part, page or area of the Hagglr
                    Sites/Medium
                    and
                    any
                    and all functions and services provided by Hagglr;</li>
                <li>Attempting to interfere with service to any user in any manner, including, without limitation, by
                    means of
                    submitting a virus to our Site, or attempts at overloading, “flooding”, “spamming”, “mail bombing”
                    or
                    “crashing”
                    the
                    Hagglr Sites/Medium;</li>
                <li>Using any Hagglr customer or merchant information that is not your information from the Hagglr
                    Sites/Medium
                    for
                    any
                    commercial purpose, including, but not limited to, marketing;</li>
                <li>Offering vouchers or otherwise using any Hagglr account to offer vouchers for resale, or for
                    speculative,
                    false,
                    fraudulent or any other purpose not expressly permitted by agreement between you and Hagglr;</li>
                <li>Accessing, monitoring or copying any content or information from any Hagglr Sites/Medium using any
                    robot,
                    spider,
                    scraper or other automated means or any manual process for any purpose without our express written
                    permission;</li>
                <li>Violating the restrictions in any robot exclusion headers on any Hagglr Sites/Medium or bypassing or
                    circumventing
                    other measures employed to prevent or limit access to the Hagglr Sites/Medium;</li>
                <li>Taking any action that places excessive demand on our services, or imposes, or may impose an
                    unreasonable or
                    disproportionately large load on our servers or other portion of our infrastructure (as determined
                    in our
                    sole
                    discretion) supporting the Hagglr Sites/Medium;</li>
                <li>Attempting to scan, or test the security or configuration of the Hagglr Sites/Medium or to breach
                    security
                    or
                    authentication measures without proper authorization;</li>
                <li>Aggregating any live or post-feature content or other information from the Hagglr Sites/Medium
                    (whether
                    using
                    links
                    or other technical means or physical records associated with purchases made through the Hagglr
                    Sites/Medium)
                    with
                    material from other Sites/Medium or on a secondary site without our express written permission.</li>
                <li>Acting illegally or maliciously against the business interests or reputation of Hagglr, our other
                    merchants
                    or
                    our
                    services; or</li>
            </ul>
        </div>
    </div>
</section>
<public-footer (NavigatePage)="navigatePageTo($event)"></public-footer>