<div id="wrapper" style="overflow: auto;">
    <member-header></member-header>
    <div class="content-page">
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h4 class="page-title">Add Deal Builder</h4>
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/member/dashboard">Dashboard</a></li>
                                <li class="breadcrumb-item"><a routerLink="/member/dealbuilder">Deal Builder</a></li>
                                <li class="breadcrumb-item active">New Deal</li>
                            </ol>
                        </div>
                        <div class="col-sm-6">
                            <div class="float-right d-md-block">
                                <a class="btn btn-primary add-btn add-btnarrow-none waves-effect waves-light"
                                    data-toggle="tooltip" data-placement="bottom" title=""
                                    data-original-title="Add Deal Builder" routerLink="/member/dealbuilder"><i
                                        class="fa fa-list mr-2"></i> List</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <form id="form-horizontal" class="form-horizontal form-wizard-wrapper"
                                    [formGroup]="dealForm" (ngSubmit)="onFirstSubmit()">

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="combo_select">Deal Type</label>
                                                <select class="form-control p-1" formControlName="dealType"
                                                    [ngClass]="{ 'is-invalid': submitted && f.dealType.errors }"
                                                    style="height: 30px;margin-top: 1px;">
                                                    <option value="" disabled selected>select</option>
                                                    <option value="Dollar">Dollar</option>
                                                    <option value="Percentage">Percentage</option>
                                                    <option value="Custom Deal">Custom Deal</option>
                                                </select>
                                                <div *ngIf="submitted && f.dealType.errors" class="invalid-feedback">
                                                    <div *ngIf="f.dealType.errors.required">Please select the deal type.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="Discount">Discount</label>
                                                <input id="Discount" name="Discount" type="text" class="form-control"
                                                    formControlName="dollarDiscount"
                                                    [ngClass]="{ 'is-invalid': submitted && f.dollarDiscount.errors }">
                                                <div *ngIf="submitted && f.dollarDiscount.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.dollarDiscount.errors.required">Please provide a valid
                                                        value.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="Description">Description</label>
                                                <textarea name="Discription" formControlName="dealDescription" rows="2"
                                                    class="form-control" maxlength="200" title="Maximum 200 chars only">
												</textarea>
                                                <div *ngIf="f.dealDescription.value == '' && f.dealDescription.touched"
                                                    class="alert alert-primary alert-dismissible fade show"
                                                    role="alert">
                                                    <strong>Description</strong> : Maximum 200 characters.
                                                    <button type="button" class="close" data-dismiss="alert"
                                                        aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="MinimumPurchase">Minimum Purchase</label>
                                                <input id="MinimumPurchase" name="MinimumPurchase" type="text"
                                                    formControlName="minimumPurchase" class="form-control">

                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="Coupons">Total Coupons</label>
                                                <input id="Coupons" name="Coupons" type="text" class="form-control"
                                                    formControlName="totalCoupons"
                                                    [ngClass]="{ 'is-invalid': submitted && f.totalCoupons.errors }">
                                                <div *ngIf="submitted && f.totalCoupons.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.totalCoupons.errors.required">Please provide a valid
                                                        value.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <!-- <div class="form-group hidden">
                                                <div class="input-group">
                                                    <input name="datepicker" class="form-control hdn-control"
                                                        ngbDatepicker #datepicker="ngbDatepicker"
                                                        [positionTarget]="dpPosition" [autoClose]="'outside'"
                                                        (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                                                        [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!">
                                                    <ng-template #t let-date let-focused="focused">
                                                        <span class="custom-day" [class.focused]="focused"
                                                            [class.range]="isRange(date)"
                                                            [class.faded]="isHovered(date) || isInside(date)"
                                                            (mouseenter)="hoveredDate = date"
                                                            (mouseleave)="hoveredDate = null">
                                                            {{ date.day }}
                                                        </span>
                                                    </ng-template>
                                                </div>
                                            </div> -->
                                            <div class="form-group">
                                                <label for="dateDuration">Start Date</label>
                                                <!-- <div #dpPosition class="input-group mt34">
                                                    <input #dpFromDate class="form-control" placeholder="yyyy-mm-dd"
                                                        name="dpFromDate" [value]="formatter.format(fromDate)"
                                                        (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                                                </div> -->
                                                <input type="date" id="fromDate" class="form-control" name="fromDate"
                                                    formControlName="fromDate"
                                                    [ngClass]="{ 'is-invalid': submitted && f.fromDate.errors }">
                                                <div *ngIf="submitted && f.fromDate.errors" class="invalid-feedback">
                                                    <div *ngIf="f.fromDate.errors.required">Please select
                                                        start date.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <label for="dateDuration">End Date</label>
                                            <!-- <div class="input-group mt34">
                                                <input #dpToDate class="form-control" placeholder="yyyy-mm-dd"
                                                   name="dpToDate"
                                                    [value]="formatter.format(toDate)"
                                                    (input)="toDate = validateInput(toDate, dpToDate.value)">
                                                <div class="input-group-append" style="margin-left: 35px;">
                                                    <button class="btn btn-outline-secondary fa fa-calendar"
                                                        (click)="datepicker.toggle();" type="button"></button>
                                                </div>
                                            </div> -->
                                            <input type="date" id="toDate" class="form-control" name="toDate"
                                                formControlName="toDate"
                                                [ngClass]="{ 'is-invalid': submitted && f.toDate.errors }">
                                            <div *ngIf="submitted && f.toDate.errors" class="invalid-feedback">
                                                <div *ngIf="f.toDate.errors.required">Please select end date.</div>
                                                <div *ngIf="f.toDate.touched && f.toDate.invalid">Please add a valid
                                                    from and to date</div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <label for="fromTime">From Time</label>
                                            <div class="input-group">
                                                <ngb-timepicker id="fromTime" formControlName="fromTime"
                                                    [minuteStep]="15" [meridian]="true"></ngb-timepicker>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <label for="toTime">To Time</label>
                                            <div class="input-group">
                                                <ngb-timepicker id="toTime" formControlName="toTime" [minuteStep]="15"
                                                    [meridian]="true"></ngb-timepicker>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group cls-day">
                                                <label class="label-checkmain">Day</label><br>
                                                <div class="row">
                                                    <div class="col-md-2 col-sm-2 col-xs-2 col-2 col-wid" *ngFor="let day of dealForm.controls.days['controls']; let i=index;">
                                                            <span><input type="checkbox" class="checker"
                                                                    [id]="weekdays[i].name" [formControl]="day"
                                                                    (change)="onCheckboxChange($event,weekdays[i].name)"></span><span><label
                                                                    class="label-check"
                                                                    
                                                                    [for]="weekdays[i].name">{{weekdays[i].slug}}</label></span>

                                                    </div>
                                                </div>
                                                <!--<div class="custom-control custom-checkbox"
                                                    *ngFor="let day of dealForm.controls.days['controls']; let i=index;"
                                                    style="display: inline-block;width: 12%;margin-top: 6px;">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [id]="weekdays[i].name" [formControl]="day"
                                                        (change)="onCheckboxChange($event,weekdays[i].name)">
                                                    <label class="custom-control-label"
                                                        [for]="weekdays[i].name">{{weekdays[i].slug}}</label>
                                                   
                                                </div>-->
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="fileup">Upload Image</label>
                                                <div class="form-group">
                                                    <input id="fileup" type="file" class="filestyle"
                                                        (change)="onFileChange($event)" data-buttonname="btn-secondary">

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">

                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="Disclaimer">Disclaimer</label>
                                                <textarea name="Disclaimer" formControlName="disclaimer" rows="2"
                                                    class="form-control" maxlength="200" title="Maximum 200 chars only">
												</textarea>
                                                <div *ngIf="f.disclaimer.value == '' && f.disclaimer.touched"
                                                    class="alert alert-primary alert-dismissible fade show"
                                                    role="alert">
                                                    <strong>Disclaimer</strong> : Maximum 200 characters.
                                                    <button type="button" class="close" data-dismiss="alert"
                                                        aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="float-center">
                                            <a data-toggle="tooltip" data-placement="top" title=""
                                                data-original-title="Edit" role="button"
                                                class="btn btn-primary waves-effect waves-light mr-1"
                                                (click)="goPreview(dealForm)">Preview</a>
                                            <button type="submit" class="btn btn-primary waves-effect waves-light mr-1">
                                                {{ (PaymentID == null || PaymentID=='') ? 'Submit' : 'Submit'
                                                }}</button>
                                            <button type="reset" (click)="goback();"
                                                class="btn btn-secondary waves-effect">Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <footer class="footer">
            <p>&copy; 2018-2021 Hagglr, Inc.</p>
        </footer>
    </div>
</div>