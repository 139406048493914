import { Component } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Router } from '@angular/router';
import { MemberApi } from 'src/app/shared/services/memberApi';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdContactModalContent } from '../modals/modal-contact.component';
import { UserService } from 'src/app/shared/services/user.service';
import { ViewEncapsulation } from '@angular/core';


@Component(
    {
        templateUrl: '../views/messages.component.html',
        styles: [`
        .table-bordered thead td, .table-bordered thead th {
            border-bottom-width: 2px;
            width: 14%;
            height: 64px;
            background: #1997D5;
            color: white;
          }
        `],
        encapsulation: ViewEncapsulation.None
    }
)
export class MessagesComponent extends BaseComponent {
    messages: any = [];
    userProfile: any;
    activeMesaages: number = 0;
    alert: any = { type: '', message: '' }
    selectedRow!: string;
    paginationData: any;
    curPage: any = 1;
    sortBy: any = 'firstname';
    sortDir: any = 'DESC';
    sortDirClass: any;

    constructor(public router: Router,
        public _api: MemberApi,
        public _userSvc: UserService,
        private modalService: NgbModal) {
        super(router);
    }

    ngOnInit() {
        //this.LoadScripts();
        this.userProfile = this._userSvc.getProfile();
        this.getLatestMessages();
    }

    LoadScripts() {
        this.RemoveScripts();
        
        this.loadLink("assets/plugins/datatables/dataTables.bootstrap4.min.css");
        this.loadLink("assets/plugins/datatables/buttons.bootstrap4.min.css");
        this.loadLink("assets/plugins/datatables/responsive.bootstrap4.min.css");
        this.loadLink("assets/css/bootstrap.min.css");
        this.loadLink("assets/css/metismenu.min.css");
        this.loadLink("assets/css/icons.css");
        this.loadLink("assets/css/style.css");


        this.loadScript('assets/js/jquery.min.js');
        this.loadScript('assets/js/bootstrap.bundle.min.js');
        this.loadScript('assets/js/metisMenu.min.js');
        this.loadScript('assets/js/jquery.slimscroll.js');
        this.loadScript('assets/js/waves.min.js');
        this.loadScript('assets/plugins/datatables/jquery.dataTables.min.js');
        this.loadScript('assets/plugins/datatables/dataTables.bootstrap4.min.js');
        this.loadScript('assets/plugins/datatables/dataTables.buttons.min.js');
        this.loadScript('assets/plugins/datatables/buttons.bootstrap4.min.js');
        this.loadScript('assets/plugins/datatables/vfs_fonts.js');
        this.loadScript('assets/plugins/datatables/buttons.html5.min.js');
        this.loadScript('assets/plugins/datatables/buttons.colVis.min.js');
        this.loadScript('assets/plugins/datatables/dataTables.responsive.min.js');
        this.loadScript('assets/plugins/datatables/responsive.bootstrap4.min.js');
        this.loadScript('assets/pages/datatables.init.js');
        this.loadScript('assets/js/app.js');
    }

    public RemoveScripts() {
        const body = <HTMLDivElement>document.body;
        var scripts = body.getElementsByTagName('script');
        var i = scripts.length;
        while (i--) {
            scripts[i].parentNode.removeChild(scripts[i]);
        }

        var links = body.getElementsByTagName('link');
        var j = links.length;
        while (j--) {
            links[j].parentNode.removeChild(links[j]);
        }
    }
    public loadScript(url: string) {

        const body = <HTMLDivElement>document.body;
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = true;
        body.appendChild(script);
    }

    public loadLink(url: string) {
        const body = <HTMLDivElement>document.body;
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = url;
        body.appendChild(link);
    }

    sortData(sortColumn: string) {
        if (this.sortBy === sortColumn) {
            this.sortDir = (this.sortDir === 'ASC') ? 'DESC' : 'ASC';
        } else {
            this.sortBy = sortColumn;
            this.sortDir = 'ASC';
        }
        // this.store.dispatch({
        //     type: 'CHANGE_SORTING',
        //     payload: { 'sortColumn': sortColumn, 'sortDir': this.sortDir }
        // });

        this.getLatestMessages();
    }

    getLatestMessages() {
        let data = {
            'post': null,
            'get': {
                'page': this.curPage,
                'limit': 25,
                'sort': this.sortBy,
                'dir': this.sortDir
            }
        }
        this.sortDirClass = (this.sortDir === 'ASC') ? 'fa-sort-up' : 'fa-sort-down';
        this._api.getContacts(data).subscribe(
            (res: any) => {
                //console.log(JSON.stringify(res));
                if (res.content && Array.isArray(res.content) && res.content.length > 0) {
                    const setPgData = async () => {
                        this.paginationData = {
                            'totalRows': res.totalElements,
                            'rowLimit': res.size,
                            'numberOfElements': res.numberOfElements
                        };
                        return;
                    }
                    setPgData().then((value) => {
                        this.messages = res.content;
                    })
                } else {
                    this.messages = [];
                }
            },
            (error: any) => {
            })
    }

    editMessage(id: any, status: any, comments: any) {
        const modalRef = this.modalService.open(NgbdContactModalContent, { size: 'lg' });
        modalRef.componentInstance.messageDetail = { messageId: id, messageStatus: status, messageComments: comments };

        modalRef.result.then(
            (data: any) => {
                this.getLatestMessages();
            },
            (reason: any) => { });
    }

    getPaginationWriteup(rowLimit: any, totalRows: any) {
        let from = 1;
        let till = +rowLimit;
        if (totalRows > rowLimit && this.curPage > 1) {
            from = +((this.curPage - 1) * rowLimit) + 1;
            till = +(this.curPage * rowLimit);
        }
        if (totalRows < till) {
            till = totalRows;
        }
        return 'Showing ' + from + ' to ' + till + ' of ' + totalRows + ' records';
    }

    onPageChange(event: any) {        
        this.curPage = event;
        this.getLatestMessages();
    }
    toggleStatus(msgID: string, currStatus: string, currComments: string, key: number) {

        if (currStatus === 'Active') {
            this.messages[key].status = 'Disabled';
        } else {
            this.messages[key].status = 'Active';
        }
        let reqObj = {
            'Id': msgID,
            'postData': {
                "comments": currComments,
                "status": this.messages[key].status
            }
        }
        this._api.UpdateMessage(reqObj).subscribe(
            (res: any) => {
                //console.log(JSON.stringify(res));
            },
            (error: any) => {
                this.alert.type = 'danger';
                this.alert.message = 'Unable to fetch the deal details. Please try again later.'
            });

    }
}