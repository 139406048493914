import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Router } from '@angular/router';

@Component({
  selector: 'public-footer',
  templateUrl: './../views/publicfooter.component.html'
})

export class PublicFooterComponent extends BaseComponent {
  isHome!: boolean;
  urlStr!: string;
  //@ViewChild("pricing") pricing: ElementRef;
  //@ViewChild("contactus") contactus: ElementRef;

  @Output() NavigatePage = new EventEmitter();
  //@Output() NavigateSection = new EventEmitter();
  constructor(public router: Router,) {
    super(router);
  }

  ngOnInit() {
    if (this.router) {
      let urlStr = this.router.url
      if (urlStr.indexOf('?') > -1) {
        urlStr = urlStr.split('?')[0];
      }
      let cleanUrlStr = urlStr.replace(/^\/|\/$/g, '');
      cleanUrlStr = cleanUrlStr.replace('/', '-');
      this.isHome = (cleanUrlStr === 'public');

      let routeUrl = urlStr.split('/');
      this.urlStr = routeUrl[routeUrl.length - 1];
    }
  }

  DoMenuNavigate(val) {
    var page = 'business';
    var section = '#' + val;
    var combine = page +','+section;
    this.NavigatePage.emit(combine);
    //this.NavigateSection.emit(section);
    return;
    // if (val == 'pricing') {
    //   this.router.navigate(['/public/business'])
    //     .then(() => {
    //       //this.pricing.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    //       //alert(val);
    //       this.NavigateBusiness.emit(val)
    //     });
    // }
    // else {
    //   this.router.navigate(['/public/business'])
    //     .then(() => {
    //       alert('hello');
    //       //this.contactus.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    //       this.NavigateBusiness.emit(val)
    //     });
    // }

  }
}