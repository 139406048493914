<div id="wrapper" style="overflow: auto;">
    <member-header></member-header>
    <div class="content-page">
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h4 class="page-title">Subscription</h4>
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a routerLink="/member/dashboard">Dashboard</a></li>
                                <li class="breadcrumb-item"><a routerLink="/member/profile">Profile</a></li>
                                <li class="breadcrumb-item active">Subscription</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>



            <!-- Start Pricing Table Area -->
            <div id="pricing" class="axil-pricing-table-area pricing-shape-position ax-section-gap bg-color-lightest">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-title text-center">
                                <h2 class="title wow">Plans & <span class="bg-all">Pricing</span></h2>
                                <p class="subtitle-2 wow words chars splitting animated">There are millions of small
                                    businesses impacted due to COVID situation. We want to help them to get back on
                                    track. We are happy to announce free subscription.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row mt--20 row--30 mt_sm--0">
                        <!-- Start Single pricing table -->
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12 offset-lg-1">
                            <div class="axil-pricing-table mt--40 active move-up wow">
                                <div class="axil-pricing-inner">
                                    <div class="pricing-header">
                                        <div class="price-wrapper">
                                            <div class="price">
                                                <h2 class="heading headin-h3">Pro</h2>
                                            </div>
                                            <div class="date-option">
                                                <a class="axil-button btn-large btn-transparent"
                                                style="height: 50px;"
                                                    [attr.disabled]="businessPlan!=null && businessPlan!='Free' && PaymentID!=''"
                                                    (click)="onSignUpFree()">
                                                    <span class="button-text">{{ (businessPlan==null ||
                                                        businessPlan!='Free' || PaymentID=='' || PaymentID==null) ?
                                                        'Sign Up for Free' : 'Cancel Subscribtion' }}</span>
                                                    </a>
                                            </div>
                                        </div>
                                        <div class="pricing-get-button">

                                        </div>
                                    </div>
                                    <div class="pricing-body" style="margin-top: 50px;">
                                        <div class="inner">
                                            <ul class="list-style" >
                                                <li>Post Unlimited Deals</li>
                                                <li>Facebook Messenger</li>
                                                <li>Slack</li>
                                                <li>Alexa</li>
                                                <li>Google Assistant</li>
                                                <li>Viber, Telegram and Whatsapp</li>
                                                <li>Bixby (Samsung Devices)</li>
                                                <li>Integration with DoorDash, UberEats, PostMates and Grubhub</li>
                                                <li>Top of the list</li>
                                                <li>Rewards Points</li>
                                                <li>Post Deals only to the Loyal and Repeat customers</li>
                                                <li>Option to Purchase Deals, Make Reservation, Book Appointments and
                                                    much more</li>
                                                <li>Auto generated deals using AI</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Single pricing table -->

                        <!-- Start Single pricing table -->
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12 offset-lg-1">
                            <div class="axil-pricing-table mt--40 active move-up wow">
                                <div class="axil-pricing-inner">
                                    <div class="pricing-header">
                                        <div class="price-wrapper">
                                            <div class="price">
                                                <h2 class="heading headin-h3">Premium</h2>
                                            </div>
                                            <div class="date-option">

                                                <a class="axil-button btn-large btn-transparent"
                                                    style="height: 50px;cursor: not-allowed;" [attr.disabled]="true"><span
                                                        class="button-text">Contact Sales</span></a>


                                            </div>
                                        </div>
                                        <div class="pricing-get-button">

                                        </div>
                                    </div>
                                    <div class="pricing-body" style="margin-top: 50px;">
                                        <div class="inner">
                                            <ul class="list-style" >
                                                <li>Post Unlimited Deals</li>
                                                <li>Facebook Messenger</li>
                                                <li>Slack</li>
                                                <li>Alexa</li>
                                                <li>Google Assistant</li>
                                                <li>Viber, Telegram and Whatsapp</li>
                                                <li>Bixby (Samsung Devices)</li>
                                                <li>Integration with DoorDash, UberEats, PostMates and Grubhub</li>
                                                <li>Top of the list</li>
                                                <li>Rewards Points</li>
                                                <li>Post Deals only to the Loyal and Repeat customers</li>
                                                <li>Option to Purchase Deals, Make Reservation, Book Appointments and
                                                    much more</li>
                                                <li>Auto generated deals using AI</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- End Single pricing table -->
                    </div>
                </div>

            </div>
            <!-- End Pricing Table Area -->


        </div>
        <footer class="footer">
            <p>&copy; 2018-2021 Hagglr, Inc.</p>
        </footer>     
    </div>
    <member-footer></member-footer>
</div>