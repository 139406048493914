<div class="card-body ">

<!-- <form [formGroup]="_form" novalidate>
	<table width="100%" class="table table-striped">
		<tbody formArrayName="deliveryOptions">
			<tr *ngFor="let array of (getControls().controls); index as passingCount;" [formGroupName]="passingCount">
				<td scope="col"><div nowrap>Delivery Partner</div>
					<select formControlName="deliveryName" (change)="setDisplay(passingCount,$event)">
					  <option [ngValue]="null">Choose Delivery Option</option>
					  <option *ngFor="let dOpt of deliveryOptionsAll" [ngValue]="dOpt.deliveryName">
						{{dOpt.displayName | titlecase}}
					  </option>
					</select>
				</td>
				<td scope="col"><div id="inputDliveryUrl_{{passingCount}}" *ngIf="!selectedLevel[passingCount]">
				<mat-label> Delivery Url:</mat-label>
					<mat-form-field>
						<input  matInput formControlName="deliveryUrl" type="text" size="50" value="{{array.value.deliveryUrl}}" (change)="validate();">
              </mat-form-field>
			  </div>
				</td>
				<td scope="col">
				<div id="inputDliveryBut_{{passingCount}}" *ngIf="!selectedLevel[passingCount]">
					<button (click)="_removeRow(passingCount)" mat-mini-fab
          color="accent" class="btn btn-primary waves-effect waves-light mr-1" *ngIf="passingCount >0">--</button>&nbsp;&nbsp;<button mat-mini-fab
          color="accent" (click)="_addRow()" class="btn btn-primary waves-effect waves-light mr-1" *ngIf="passingCount ==0">+</button>
		  </div>
				</td>
			</tr>
			
			<div *ngIf="getControls().length < 1 && firstLoad()" align=center>
				{{"No delivery options"}}
			</div>
		</tbody>
	</table> 

</form>-->

<div>
<p>Delivery partner selection would be one or more than one based on your location and service availability</p>
<form #userDelivery="ngForm">
<!-- {{userDelivery.value|json}} -->
<table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Delivery Partner</th>
    <th scope="col" nowrap>Delivery Url</th>
    <th scope="col">&nbsp;</th>
  </tr>
  
  <tr >
	<th><div id="" class="row form-group">
			<select name="lst_deliveryName" required 
			[class.is-invalid]="list.invalid && list.touched" class="form-control p-1" 
			#list="ngModel" ngModel 
			(change)="checkDeliverySelection($event)">
			<option value="" selected>Choose Your Option</option>
			<option *ngFor="let dOpt of deliveryOptionsAll" value="{{dOpt.deliveryName}}" >
			{{ dOpt.deliveryName | uppercase }}</option>
			</select>			
		</div>
	</th>
	<th><div id="" class="form-group">
			<input type="url" name="inputDeliveryUrl" required 
			[class.is-invalid]="url.invalid && url.touched" class="form-control" 
			#url="ngModel"  ngModel (input)="txtDeliveryUrl=$event.target.value" placeholder="https://example.com/"
			pattern="https://.*" size="30">
		</div>
	</th>
	<th>
		<div id="" class="">
			<button (click)="_addRow()" class="btn btn-primary waves-effect waves-light ">+
		</button>
		</div>
	</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let array of (getControls().controls) index as rowCount">
    <td> 
       <!-- <img src="./assets/images/g2.png" alt="add"  class="img-fluid img-thumbnail thumb-lg"> -->
     {{ array.value.deliveryName | uppercase}}
    </td>
    <td align="left" nowrap>{{ array.value.deliveryUrl}}</td>
    <td width="8%" align="right"><button (click)="_removeRow(rowCount)" mat-mini-fab
          color="accent" class="btn btn-primary waves-effect waves-light mr-1">--</button></td>
  </tr>
  </tbody>
</table>

</form>
</div><!-- card body end here -->


<pre><!-- {{_form.value | json}}  -->
  </pre>
</div>
